/*--
    - Custom Row
------------------------------------------*/
.row-40 {
  margin-left: -40px;
  margin-right: -40px; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px; } }

@media only screen and (max-width: 767px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px; } }

.row-40 > [class*="col"] {
  padding-left: 40px;
  padding-right: 40px; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (max-width: 767px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; } }

/*-- 
      - Margin & Padding
  -----------------------------------------*/
/*-- Margin Top --*/
.mt-0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-85 {
  margin-top: 85px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-65 {
    margin-top: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-45 {
    margin-top: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-55 {
    margin-top: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-15 {
    margin-top: 15px !important; } }

@media only screen and (max-width: 767px) {
  .mt-xs-0 {
    margin-top: 0px !important; } }

/*-- Margin Bottom --*/
.mb-0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-30 {
    margin-bottom: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-35 {
    margin-bottom: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-40 {
    margin-bottom: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-50 {
    margin-bottom: 50px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-20 {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-30 {
    margin-bottom: 30px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-35 {
    margin-bottom: 35px !important; } }

@media only screen and (max-width: 767px) {
  .mb-xs-40 {
    margin-bottom: 40px !important; } }

/*-- Margin Left --*/
/*-- Margin Right --*/
/*-- Padding Top --*/
.pt-0 {
  padding-top: 0px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-145 {
  padding-top: 145px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-15 {
    padding-top: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-25 {
    padding-top: 25px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-45 {
    padding-top: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-60 {
    padding-top: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-75 {
    padding-top: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-80 {
    padding-top: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-0 {
    padding-top: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-5 {
    padding-top: 5px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-15 {
    padding-top: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-20 {
    padding-top: 20px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-45 {
    padding-top: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-65 {
    padding-top: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-70 {
    padding-top: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-0 {
    padding-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-5 {
    padding-top: 5px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-10 {
    padding-top: 10px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-20 {
    padding-top: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-30 {
    padding-top: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-40 {
    padding-top: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-50 {
    padding-top: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-55 {
    padding-top: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-60 {
    padding-top: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-0 {
    padding-top: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-5 {
    padding-top: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-20 {
    padding-top: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-30 {
    padding-top: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-35 {
    padding-top: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-40 {
    padding-top: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-45 {
    padding-top: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pt-xs-50 {
    padding-top: 50px !important; } }

/*-- Padding Bottom --*/
.pb-0 {
  padding-bottom: 0px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-45 {
    padding-bottom: 45px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-55 {
    padding-bottom: 55px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-60 {
    padding-bottom: 60px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-70 {
    padding-bottom: 70px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-75 {
    padding-bottom: 75px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-80 {
    padding-bottom: 80px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-25 {
    padding-bottom: 25px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-30 {
    padding-bottom: 30px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-45 {
    padding-bottom: 45px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-60 {
    padding-bottom: 60px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-65 {
    padding-bottom: 65px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-70 {
    padding-bottom: 70px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-15 {
    padding-bottom: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-20 {
    padding-bottom: 20px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-25 {
    padding-bottom: 25px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-30 {
    padding-bottom: 30px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-35 {
    padding-bottom: 35px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-40 {
    padding-bottom: 40px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-50 {
    padding-bottom: 50px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-55 {
    padding-bottom: 55px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-60 {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-5 {
    padding-bottom: 5px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-10 {
    padding-bottom: 10px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-15 {
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-20 {
    padding-bottom: 20px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-25 {
    padding-bottom: 25px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-30 {
    padding-bottom: 30px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-35 {
    padding-bottom: 35px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-40 {
    padding-bottom: 40px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-45 {
    padding-bottom: 45px !important; } }

@media only screen and (max-width: 767px) {
  .pb-xs-50 {
    padding-bottom: 50px !important; } }

/*-- Padding Left --*/
.pl-20 {
  padding-left: 20px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-115 {
  padding-left: 115px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-15 {
    padding-left: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-15 {
    padding-left: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-15 {
    padding-left: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-0 {
    padding-left: 0px !important; } }

@media only screen and (max-width: 767px) {
  .pl-xs-15 {
    padding-left: 15px !important; } }

/*-- Padding Right --*/
.pr-20 {
  padding-right: 20px !important; }

.pr-115 {
  padding-right: 115px !important; }

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-15 {
    padding-right: 15px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-15 {
    padding-right: 15px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-15 {
    padding-right: 15px !important; } }

@media only screen and (max-width: 767px) {
  .pr-xs-15 {
    padding-right: 15px !important; } }
