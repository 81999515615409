@charset "UTF-8";








/*-----------------------------------------------------------------------------------

    Template Name: Ginza - Furniture eCommerce Bootstrap4 Template
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. About CSS
    05. Banner CSS
    06. Feature CSS
    07. Product CSS
    08. Cart CSS
    09. Checkout CSS
    10. Compare CSS
    11. Faq CSS
    12. Blog CSS
    13. Login Register CSS
    14. My Account CSS
    15. Newsletter CSS
    16. Testimonial CSS
    17. Modal CSS
    18. Sidebar CSS
    19. Contact CSS
    20. 404 CSS
    21. Footer CSS

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Karla:700|Poppins:300,400,500,600,700");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html, body, .site-wrapper {
  height: 100%;
}

body {
  background-color: #ffffff;
  font-size: 13px;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  visibility: visible;
  font-family: "Poppins", sans-serif;
  color: #929292;
  position: relative;
}
body[data-rtl=rtl] {
  direction: rtl;
  text-align: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  color: #292929;
  font-weight: 300;
  margin-top: 0;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #E8620F;
}

button, input[type=submit] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section, .main-wrapper {
  float: center;
  width: 100%;
}

.bt-0 {
  border-top: 0 !important;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bl-0 {
  border-left: 0 !important;
}

.br-0 {
  border-right: 0 !important;
}

.bg-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.st-border {
  border-top: 1px solid #ebebeb;
}

.sb-border {
  border-bottom: 1px solid #ebebeb;
}

.ft-border {
  border-top: 2px solid #ebebeb;
}

#scrollUp {
  background: #222;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  bottom: 25px;
  right: 25px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#scrollUp:hover {
  background: #E8620F;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .row-five-column > [class*=col-xl-] {
    max-width: 20%;
    flex: 0 0 20%;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}
@media only screen and (max-width: 479px) {
  .container {
    max-width: 300px;
  }
}
.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
  margin: 0 !important;
}

.inline-YTPlayer {
  max-width: none !important;
  width: 100%;
}

.mbYTP_wrapper {
  z-index: -9 !important;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*--
    - Background Color
------------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #f1f1f1 !important;
}

.bg-gray-two {
  background-color: #f6f6f6 !important;
}

.bg-gray-three {
  background-color: #f7f7f7 !important;
}

.bg-dark {
  background-color: #292929 !important;
}

.bg-theme {
  background-color: #E8620F !important;
}

.bg-black {
  background-color: #000000 !important;
}

/*-- 
    - Tab Content & Pane Fix
------------------------------------------*/
.tab-content {
  width: 100%;
}
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}
.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-- 
    - Main Wrapper
------------------------------------------*/
/*-- 
    - Section Title
------------------------------------------*/
.section-title h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  text-transform: capitalize;
}
.section-title h2 span {
  color: #E8620F;
}
.section-title p {
  font-size: 13px;
  line-height: 23px;
  max-width: 635px;
  margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .section-title p {
    max-width: 435px;
  }
}
.section-title.text-center {
  text-align: center;
  background-position: top center;
}
.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}
.section-title.text-left {
  text-align: left;
  background-position: top left;
}
.section-title.text-left p {
  margin-left: 0;
  margin-right: auto;
}
.section-title.text-right {
  text-align: right;
  background-position: top right;
}
.section-title.text-right p {
  margin-left: auto;
  margin-right: 0;
}

.section-title-two h2 {
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 20px;
}
.section-title-two h2::before {
  content: "";
  background: #292929;
  display: block;
  height: 2px;
  margin-bottom: 20px;
  width: 40px;
}
.section-title-two p {
  font-size: 13px;
  line-height: 23px;
  max-width: 635px;
  margin-top: 15px;
}

/*-- List Section Title CSS --*/
.list-product-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-product-section-title h3 {
  display: block;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  margin: 0;
  text-transform: capitalize;
  position: relative;
  z-index: 5;
  flex-basis: 100%;
  max-width: 100%;
}
.list-product-section-title h3 span {
  background-color: #ffffff;
  padding-right: 30px;
  display: inline-block;
  position: relative;
  z-index: 5;
}
.list-product-section-title h3::after {
  background: #ebebeb;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2;
}

/*--  Tab Menu CSS --*/
.product-title {
  text-align: center;
  padding: 15px;
  position: relative;
}
.product-title::before {
  background: #f0f0f0;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
  z-index: 9;
}
.product-title h3 {
  font-size: 21px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .product-title h3 {
    font-size: 18px;
  }
}
.product-title h2 {
  font-size: 39px;
  line-height: 1.2;
  color: #929292;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .product-title h2 {
    font-size: 30px;
  }
}

.product-tab-menu ul {
  flex-wrap: wrap;
}
.product-tab-menu ul li {
  flex-basis: 100%;
  text-align: center;
  display: block;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 767px) {
  .product-tab-menu ul li {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .product-tab-menu ul li {
    flex-basis: 100%;
  }
}
.product-tab-menu ul li a {
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  display: block;
  padding: 23px 25px;
  font-weight: 500;
  background-color: #f0f0f0;
}
@media only screen and (max-width: 767px) {
  .product-tab-menu ul li a {
    padding: 15px 25px;
  }
}
.product-tab-menu ul li a.active, .product-tab-menu ul li a:hover {
  background-color: #E8620F;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  .product-tab-menu2 ul {
    justify-content: flex-start !important;
  }
}
.product-tab-menu2 ul li {
  border-right: 1px solid #e5e5e5;
  padding-right: 30px;
  margin-right: 25px;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .product-tab-menu2 ul li {
    padding-right: 20px;
    margin-right: 15px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .product-tab-menu2 ul li {
    padding-right: 10px;
    margin-right: 7px;
    line-height: 30px;
  }
}
.product-tab-menu2 ul li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.product-tab-menu2 ul li a {
  color: #292929;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}
.product-tab-menu2 ul li a.active, .product-tab-menu2 ul li a:hover {
  color: #E8620F;
}

/*-- 
    - Button
------------------------------------------*/
.btn {
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #ebebeb;
  font-size: 15px;
  height: 55px;
  line-height: 51px;
  font-weight: 500;
  padding: 0px 35px;
  text-transform: capitalize;
  border-radius: 0px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.btn:focus {
  box-shadow: none;
  outline: none;
}
.btn:hover {
  color: #ffffff;
  background-color: #E8620F;
  border-color: #E8620F;
}
@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
    line-height: 23px;
    height: 45px;
    padding: 10px 25px;
  }
  .btn:hover::before {
    left: 6px;
    top: 6px;
  }
  .btn:hover::after {
    left: -6px;
    top: -6px;
  }
}
/*-- 
    - Page Banner Section
------------------------------------------*/
.page-banner-section {
  padding: 120px 0 130px;
  position: relative;
  z-index: 1;
  margin: auto;
  width: calc(100% - 90px);
  float: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .page-banner-section {
    padding: 100px 0 110px;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner-section {
    padding: 90px 0 100px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-section {
    padding: 80px 0 90px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner-section {
    padding: 70px 0 80px;
    width: 100%;
  }
}

/*-- Page Banner --*/
.page-banner h2 {
  font-size: 39px;
  font-weight: 500;
  text-transform: capitalize;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .page-banner h2 {
    font-size: 26px;
  }
}

/*-- Page Breadcrumb --*/
.page-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.page-breadcrumb li {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  margin-top: 10px;
  color: #E8620F;
}
@media only screen and (max-width: 575px) {
  .page-breadcrumb li {
    font-size: 13px;
  }
}
.page-breadcrumb li::after {
  content: "/";
  margin: 0 6px;
  color: #929292;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a {
  color: #929292;
}
.page-breadcrumb li a:hover {
  color: #E8620F;
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .page-pagination {
    margin-top: 10px;
  }
}
.page-pagination li {
  margin: 0 15px 0 0;
  vertical-align: middle;
}
.page-pagination li:last-child {
  margin-right: 0;
}
.page-pagination li a {
  height: 30px;
  line-height: 30px;
  min-width: 0;
  padding: 0 5px;
}
.page-pagination li:hover a {
  color: #E8620F;
}
.page-pagination li.active a {
  color: #E8620F;
}

.grid-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.grid-filter button {
  background-color: transparent;
  color: #292929;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px 10px;
  text-transform: capitalize;
  line-height: 1;
  padding-bottom: 5px;
  position: relative;
}
.grid-filter button::before {
  content: "";
  height: 6px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: 4px;
  background-color: #E8620F;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.grid-filter button:hover::before, .grid-filter button.active::before {
  width: 100%;
}
.grid-filter.center {
  justify-content: center;
}
.grid-filter.center button {
  margin: 0 10px 10px;
}
.grid-filter.left {
  justify-content: flex-start;
}
.grid-filter.left button {
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 10px;
}
.grid-filter.left button:last-child {
  margin-right: 0;
}
.grid-filter.right {
  justify-content: flex-end;
}
.grid-filter.right button {
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 10px;
}
.grid-filter.right button:last-child {
  margin-left: 0;
}

.slick-btns {
  display: flex;
  background: #ffffff;
}
.slick-btns .slick-btn {
  width: 45px;
  height: 45px;
  color: #9e9e9e;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  line-height: 42px;
  font-size: 16px;
  margin-right: 5px;
}
.slick-btns .slick-btn:last-child {
  margin-right: 0;
}
.slick-btns .slick-btn:hover {
  background-color: #E8620F;
  border-color: #E8620F;
  color: #ffffff;
}

.slick-slider.top-nav .slick-arrow {
  margin-top: -70px;
}
.slick-slider.top-nav .slick-arrow.slick-prev {
  right: 50px;
}
.slick-slider.top-nav .slick-arrow.slick-next {
  right: 0px;
}
.slick-slider .slick-arrow {
  position: absolute;
  top: 0;
  margin-top: -55px;
  z-index: 9;
  width: 45px;
  height: 45px;
  color: #9e9e9e;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .slick-slider .slick-arrow {
    margin-top: -55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slick-slider .slick-arrow {
    margin-top: -55px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slick-slider .slick-arrow {
    margin-top: -55px;
  }
}
@media only screen and (max-width: 767px) {
  .slick-slider .slick-arrow {
    margin-top: -55px;
  }
}
.slick-slider .slick-arrow i {
  font-size: 18px;
  line-height: 42px;
  display: block;
}
.slick-slider .slick-arrow.slick-prev {
  right: 60px;
}
.slick-slider .slick-arrow.slick-next {
  right: 10px;
}
.slick-slider .slick-arrow:hover {
  color: #ffffff;
  background-color: #E8620F;
  border-color: #E8620F;
}
.slick-slider .slick-arrow:hover::before {
  margin-left: 6px;
  margin-top: 6px;
}
.slick-slider .slick-dots {
  width: 100%;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
}
.slick-slider .slick-dots li {
  margin: 0 5px;
}
.slick-slider .slick-dots li button {
  display: block;
  padding: 0;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  background-color: #929292;
  text-indent: -9999px;
  border-radius: 100%;
}
.slick-slider .slick-dots li.slick-active button {
  border-color: #E8620F;
  background-color: #ffffff;
}

.normal-nav .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  margin-top: 0;
  width: 45px;
  height: 45px;
  color: #9e9e9e;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
.normal-nav .slick-arrow.slick-prev {
  left: 15px;
}
.normal-nav .slick-arrow.slick-next {
  right: 15px;
}
.normal-nav:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.gallery-item {
  position: relative;
}
.gallery-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #292929;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.gallery-item img {
  width: 100%;
}
.gallery-item .plus {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  opacity: 0;
}
.gallery-item .plus::before, .gallery-item .plus::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  transition: all 0.3s ease 0s;
}
.gallery-item .plus::before {
  width: 150px;
  height: 1px;
}
.gallery-item .plus::after {
  width: 1px;
  height: 150px;
}
.gallery-item:hover::before {
  opacity: 0.75;
}
.gallery-item:hover .plus {
  opacity: 1;
}
.gallery-item:hover .plus::before {
  width: 40px;
}
.gallery-item:hover .plus::after {
  height: 40px;
}

blockquote.blockquote {
  background-color: #f1f2f3;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
blockquote.blockquote::before {
  position: absolute;
  left: -5px;
  top: -10px;
  z-index: -1;
  opacity: 0.07;
}
blockquote.blockquote p {
  font-size: 18px;
  font-style: italic;
}
blockquote.blockquote .author {
  font-size: 14px;
  display: block;
  line-height: 18px;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
header.header {
  background-color: #000000;
  position:relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
header.NavBar{
  animation: SlideMe 0.13s;
}

@keyframes SlideMe {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 60px;
  }
}
header.header.is-sticky {
  position: fixed !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-duration: 0.5s;
  background-color: #000000;
}
header.header.is-sticky .header-top {
  display: none;
}
header.header.is-sticky .header-bottom .main-menu > ul > li > a {
  padding: 30px 14px;
  margin: 0 20px 0 0;
}

.header-transparent {
  background-color: transparent !important;
}

/*-- Header Top --*/
.header-top {
  background-color: #ffffff;
  padding: 35px 0 25px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top {
    padding-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header-top {
    padding-top: 10px;
  }
}
.header-top.bg-dark {
  border-color: #535353;
}
.header-top > .container > .row > .col {
  flex-grow: 0;
  margin: 6px 0;
  display: flex;
}
.header-top > .container > .row > .col:first-child {
  flex-grow: 1;
}
@media only screen and (max-width: 575px) {
  .header-top > .container > .row > .col {
    flex: 1 0 100%;
    justify-content: center;
    margin: 0;
  }
  .header-top > .container > .row > .col:first-child {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .header-top > .container > .row > .col:last-child {
    margin-bottom: 10px;
  }
}

/*-- Header Top Links --*/
.header-top-links ul {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .header-top-links ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.header-top-links ul li {
  font-size: 15px;
  line-height: 26px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  color: #929292;
}
@media only screen and (max-width: 767px) {
  .header-top-links ul li {
    flex-wrap: wrap;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .header-top-links ul li {
    flex-wrap: wrap;
    margin-right: 10px;
    display: block;
  }
}
.header-top-links ul li:last-child {
  margin-right: 0;
}
.header-top-links ul li i {
  margin-right: 6px;
  font-size: 18px;
  line-height: 26px;
  float: left;
  padding-top: 2px;
}
.header-top-links ul li a {
  display: block;
}
.header-top-links.color-white ul li {
  color: #d8d8d8;
}

/*-- Header Top Social --*/
.header-top-social {
  display: flex;
  justify-content: flex-start;
}
.header-top-social a {
  margin-right: 25px;
}
.header-top-social a:last-child {
  margin-right: 0;
}
.header-top-social a i {
  font-size: 14px;
  display: block;
  line-height: 28px;
}
.header-top-social a:hover {
  color: #E8620F;
}
.header-top-social.color-white a {
  color: #d8d8d8;
}
.header-top-social.color-white a:hover {
  color: #E8620F;
}

/*-- Header Top Currency Menu --*/
.ht-us-menu > li {
  position: relative;
  margin-left: 15px;
  text-align: center;
}
.ht-us-menu > li > a {
  color: #f6f6f6;
  display: block;
  font-weight: normal;
  position: relative;
  text-transform: capitalize;
  z-index: 10;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ht-us-menu > li > a:hover {
  color: #E8620F;
}
.ht-us-menu > li > a i {
  font-size: 22px;
}
.ht-us-menu > li:hover .ht-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ht-dropdown {
  position: absolute;
  top: 80%;
  right: 0;
  width: 150px;
  background-color: #fff;
  padding: 20px 25px;
  z-index: 101;
  text-align: right;
  border: 1px solid #ebebeb;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .ht-dropdown {
    right: auto;
    left: 0;
  }
}
.ht-dropdown li {
  margin-bottom: 10px;
}
.ht-dropdown li:last-child {
  margin-bottom: 0;
}
.ht-dropdown li a {
  display: block;
  font-weight: normal;
  border-top: none;
  height: inherit;
  border-radius: 0;
  text-transform: capitalize;
  position: relative;
  z-index: 10;
  font-size: 13px;
}
.ht-dropdown li a:hover {
  color: #E8620F;
}

/*-- Header Bottom --*/
.header-bottom.bg-dark {
  border-color: #393939;
}
.header-bottom.menu-right > .container > .row {
  align-items: center;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-bottom.menu-right > .container > .row {
    align-items: inherit;
  }
}
@media only screen and (max-width: 767px) {
  .header-bottom.menu-right > .container > .row {
    align-items: inherit;
  }
}
.header-bottom.menu-right > .container > .row > .col {
  flex-grow: 0;
  position: static;
}
.header-bottom.menu-right > .container > .row > .col:first-child {
  flex-grow: 1;
}

/*-- Header Logo --*/
@media only screen and (max-width: 479px) {
  .header-logo {
    width: 200px;
  }
}
.header-logo a {
  display: inline-block;
}
.header-logo a img {
  width: 200px;
}

/*-- Header Search --*/
.header-search {
  margin-left: 50px;
  display: flex;
  align-items: center;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-search {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-search {
    margin-left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .header-search {
    margin-left: 0;
  }
}

.header-search-toggle {
  background-color: transparent;
  border: none;
  color: #f6f6f6;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.header-search-toggle i {
  font-size: 22px;
}
.header-search-toggle.open i {
  font-size: 22px;
}
.header-search-toggle.color-white {
  color: #ffffff;
  border-color: #ffffff;
}

/*-- Search Form --*/
.header-search-form {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 100;
}
@media only screen and (max-width: 479px) {
  .header-search-form {
    right: -85px;
  }
}
.header-search-form form {
  display: flex;
}
.header-search-form form input {
  width: 250px;
  border: none;
  background-color: transparent;
  color: #929292;
  line-height: 24px;
  padding: 13px 20px;
}
@media only screen and (max-width: 479px) {
  .header-search-form form input {
    width: 216px;
  }
}
.header-search-form form button {
  line-height: 24px;
  padding: 13px 15px;
  border: none;
  background-color: #E8620F;
  display: flex;
  color: #ffffff;
}
.header-search-form form button i {
  font-size: 22px;
  line-height: 22px;
}

/*-- Header Cart --*/
.header-cart {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-cart {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .header-cart {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 479px) {
  .header-cart {
    margin-right: 40px;
  }
}
.header-cart > a {
  background-color: transparent;
  border: none;
  color: #292929;
  padding: 0;
  line-height: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.header-cart > a i {
  font-size: 22px;
}
.header-cart > a span {
  position: absolute;
  right: 5px;
  top: -2px;
  color: #E8620F;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  border-radius: 50%;
}
.header-cart:hover .header-cart-dropdown {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header-cart.color-white > a {
  color: #ffffff;
  border-color: #ffffff;
}

.header-cart-dropdown {
  position: absolute;
  width: 320px;
  background: #fff;
  right: 15px;
  top: 120%;
  padding: 5px 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-cart-dropdown {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header-cart-dropdown {
    display: none;
  }
}

.cart-img {
  float: left;
  width: 36%;
  margin-right: 20px;
  position: relative;
}

.cart-content {
  float: left;
  width: 56%;
}

ul.cart-items li {
  list-style: none;
  display: block;
  overflow: hidden;
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
  position: relative;
  text-align: left;
}

.cart-content span {
  color: #222;
  font-weight: 400;
  font-size: 16px;
}
.cart-content span.product-price {
  color: #E8620F;
  font-size: 16px;
}

.product-name {
  color: #222;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  width: 123px;
}

.cart-item-remove a {
  position: absolute;
  color: #222;
  right: 2px;
  top: 18px;
  display: inline-block;
  left: auto;
  font-size: 20px;
}
.cart-item-remove a:hover {
  color: #E8620F;
}

.cart-total {
  overflow: hidden;
  padding: 20px 0;
  text-align: left;
  border-bottom: 1px solid #ededed;
}
.cart-total h5 {
  color: #3e3d43;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}

.cart-btn {
  padding: 20px;
  background-color: #fff;
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -5px;
}
.cart-btn a {
  display: block;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  padding: 10px 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.cart-btn a:first-child {
  margin-bottom: 10px;
}
.cart-btn a:hover {
  background: #E8620F;
  border-color: #E8620F;
  color: #fff;
}

/*-- Main Menu --*/
.main-menu > ul {
  display: flex;
}
.main-menu > ul > li {
  position: relative;
}
.main-menu > ul > li > a {
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #f6f6f6;
  text-transform: uppercase;
  display: block;
  padding: 40px 0 40px 0;
  margin: 0 37px 0 0;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > ul > li > a {
    padding: 30px 0 30px 0;
  }
}
.main-menu > ul > li.has-dropdown > a::after {
  content: "";
  font-family: Fontawesome;
  line-height: 30px;
  margin-left: 3px;
}
.main-menu > ul > li.active > a, .main-menu > ul > li:hover > a {
  color: #E8620F;
}
.main-menu > ul > li:hover > .sub-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.main-menu > ul > li:hover > .mega-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.main-menu > ul > li:last-child .sub-menu {
  left: auto;
  right: 0;
}
.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}
.main-menu > ul > li:last-child .sub-menu .sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.main-menu > ul > li:nth-last-child(-n+3) .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-two > ul > li > a {
    padding: 30px 12px;
    margin: 0;
  }
}

/*-- Sub Menu --*/
.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 30px;
  padding: 20px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease 0s;
  width: 210px;
  z-index: -99;
  opacity: 0;
  visibility: hidden;
}
.sub-menu li {
  margin-bottom: 5px;
  position: relative;
}
.sub-menu li:last-child {
  margin-bottom: 0;
}
.sub-menu li a {
  font-family: "Poppins", sans-serif;
  color: #292929;
  display: block;
  font-size: 13px;
  line-height: 35px;
  font-weight: 400;
  padding: 0 20px;
}
.sub-menu li.has-dropdown > a::after {
  content: "";
  font-family: Fontawesome;
  line-height: 30px;
  float: right;
}
.sub-menu li.active > a {
  color: #E8620F;
}
.sub-menu li .sub-menu {
  left: 100%;
  top: 0;
  margin-left: 0;
}
.sub-menu li:hover > a {
  color: #E8620F;
  padding-left: 25px;
}
.sub-menu li:hover > .sub-menu {
  margin-top: -10px;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.sub-menu li .sub-menu {
  left: 100%;
  margin-left: 0;
  top: 0;
}
.sub-menu li .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}
.sub-menu li .sub-menu .sub-menu .sub-menu {
  left: 100%;
  right: auto;
}

/*-- Mega Menu --*/
.mega-menu.four-column {
  width: 1000px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu.four-column {
    width: 770px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu.four-column {
    width: 770px;
  }
}

.mega-menu {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 30px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-bottom: 2px solid #E8620F;
  text-align: left;
  z-index: 999;
  width: 1000px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu {
    width: 770px;
    left: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu {
    width: 770px;
    left: -160px;
  }
}
.mega-menu.left-0 {
  left: 0;
}
.mega-menu > li {
  width: 33%;
  padding: 0px 13px;
  margin: 34px 0;
  float: left;
  border-left: 1px solid #eeeeee;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li {
    padding: 0 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li {
    padding: 0 5px;
  }
}
.mega-menu > li:first-child {
  border-left: 0;
}
.mega-menu > li > a {
  margin: 0 0 12px 12px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 20px;
  color: #292929;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li > a {
    margin: 0 0 12px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li > a {
    margin: 0 0 12px 20px;
  }
}
.mega-menu > li > ul > li {
  display: block;
}
.mega-menu > li > ul > li > a {
  padding: 0px 12px;
  display: block;
  font-size: 13px;
  line-height: 35px;
  font-weight: 400;
  color: #292929;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mega-menu > li > ul > li > a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-menu > li > ul > li > a {
    padding: 0 20px;
  }
}
.mega-menu > li > ul > li > a:hover {
  color: #E8620F;
}

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0;
}
.header-mobile-navigation .mobile-navigation ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-mobile-navigation .mobile-navigation ul li {
  margin-left: 20px;
}
.header-mobile-navigation .mobile-navigation ul li:first-child {
  margin-left: 0;
}
.header-mobile-navigation .mobile-navigation ul li a {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #292929;
}
.header-mobile-navigation .mobile-navigation .header-cart-icon {
  position: relative;
  display: inline-block;
}
.header-mobile-navigation .mobile-navigation .header-cart-icon a {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #292929;
}
.header-mobile-navigation .mobile-navigation .header-cart-icon a span {
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: -5px;
  left: 50%;
  width: 18px;
  height: 18px;
  color: #E8620F;
  border-radius: 100%;
}

.offcanvas-widget-area {
  margin-bottom: 35px;
}
@media only screen and (max-width: 479px) {
  .offcanvas-widget-area {
    margin-bottom: 30px;
  }
}

.off-canvas-contact-widget {
  margin-bottom: 20px;
}
.off-canvas-contact-widget .header-contact-info {
  flex-basis: 33.33%;
}
.off-canvas-contact-widget .header-contact-info .header-contact-info-list li {
  display: inline-block;
  margin-right: 25px;
}
.off-canvas-contact-widget .header-contact-info .header-contact-info-list li i {
  font-size: 14px;
  margin-right: 5px;
}
.off-canvas-contact-widget .header-contact-info .header-contact-info-list li a {
  font-weight: 400;
  line-height: 22px;
  color: #292929;
}
.off-canvas-contact-widget .header-contact-info .header-contact-info-list li a:hover {
  color: #E8620F;
}
.off-canvas-contact-widget .header-contact-info .header-contact-info-list li:last-child {
  margin-right: 0;
}

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s;
}
.offcanvas-mobile-menu.active {
  transform: translateX(0);
}
.offcanvas-mobile-menu.inactive {
  transform: translateX(calc(100% + 60px));
}

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #000;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
}
@media only screen and (max-width: 479px) {
  .offcanvas-menu-close {
    width: 50px;
    height: 50px;
    line-height: 55px;
    left: 10px;
    font-size: 25px;
  }
}
.offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
  color: #000000;
}
.offcanvas-menu-close i {
  transition: 0.3s;
  transform: rotate(0);
}
.offcanvas-menu-close:hover i {
  transform: rotate(-90deg);
}

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px;
}
.offcanvas-mobile-search-area input {
  width: 100%;
  font-size: 16px;
  display: block;
  padding: 9px 25px;
  padding-right: 35px;
  color: #222;
  background: #e6e6e6;
  border: none;
}
@media only screen and (max-width: 479px) {
  .offcanvas-mobile-search-area input {
    font-size: 14px;
    padding: 5px 15px;
    padding-right: 35px;
  }
}
.offcanvas-mobile-search-area button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  padding: 0;
}
.offcanvas-mobile-search-area button i {
  font-size: 18px;
  line-height: 40px;
}

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 479px) {
  .offcanvas-inner-content {
    padding: 70px 25px 0;
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;
}
.offcanvas-navigation > ul > li > a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 10px 0;
  color: #292929;
}
.offcanvas-navigation > ul > li > a:hover {
  color: #E8620F;
}
@media only screen and (max-width: 479px) {
  .offcanvas-navigation > ul > li > a {
    font-size: 14px;
    line-height: 20px;
  }
}
.offcanvas-navigation ul.submenu2 {
  margin-left: 25px;
}
.offcanvas-navigation ul.submenu2 > li > a {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  padding: 10px 0;
}
.offcanvas-navigation ul.submenu2 > li > a:hover {
  color: #E8620F;
}
@media only screen and (max-width: 479px) {
  .offcanvas-navigation ul.submenu2 > li > a {
    font-size: 13px;
    line-height: 18px;
  }
}
.offcanvas-navigation ul li.menu-item-has-children {
  position: relative;
  display: block;
}
.offcanvas-navigation ul li.menu-item-has-children a {
  display: block;
}
.offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}
.offcanvas-navigation ul li.menu-item-has-children .menu-expand {
  position: absolute;
  right: auto;
  left: 95%;
  top: -5px;
  width: 30px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}
.offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
  display: block;
  margin-top: 25px;
  border-bottom: 1px solid;
  position: relative;
  width: 10px;
  transition: all 250ms ease-out;
}
.offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
  width: 100%;
  content: "";
  border-bottom: 1px solid;
  display: block;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
}

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px;
}
.off-canvas-widget-social a:first-child {
  margin-left: 0;
}
@media only screen and (max-width: 479px) {
  .off-canvas-widget-social a {
    margin: 0 10px;
  }
}
.off-canvas-widget-social a:hover {
  color: #E8620F;
}

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0;
}
.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  top: -15px;
  margin-top: 0;
}
.offcanvas-settings .offcanvas-navigation ul.sub-menu > li > a {
  padding: 5px 0;
}

/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
/*-- Hero Slider --*/
.slider-nav .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  border: none;
  background-color: transparent;
  color: #999999;
  padding: 0px;
  width: auto;
  height: auto;
  border: 0;
  border-radius: 0;
  transform: rotate(90deg);
  font-size: 24px;
  text-transform: capitalize;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav .slick-arrow {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-arrow {
    font-size: 18px;
  }
}
.slider-nav .slick-arrow::before {
  display: none;
}
.slider-nav .slick-arrow.slick-next {
  margin-right: 0;
  margin-top: -60px;
  left: auto !important;
  right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav .slick-arrow.slick-next {
    margin-top: -40px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-arrow.slick-next {
    margin-top: -30px;
  }
}
.slider-nav .slick-arrow.slick-prev {
  margin-left: 0;
  margin-top: 60px;
  left: auto !important;
  right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav .slick-arrow.slick-prev {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-arrow.slick-prev {
    margin-top: 30px;
  }
}
.slider-nav .slick-arrow:hover {
  background-color: transparent;
  color: #E8620F;
}
.slider-nav:hover .slick-arrow {
  opacity: 1;
}
.slider-nav:hover .slick-arrow.slick-next {
  margin-right: 0;
}
.slider-nav:hover .slick-arrow.slick-prev {
  margin-left: 0;
}
.slider-nav .slick-dots {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-nav .slick-dots {
    bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-dots {
    bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .slider-nav .slick-dots {
    bottom: 15px;
  }
}
.slider-nav .slick-dots li {
  margin: 0 5px;
}
.slider-nav .slick-dots li button {
  display: block;
  padding: 0;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  background-color: #929292;
  text-indent: -9999px;
  border-radius: 100%;
}
.slider-nav .slick-dots li.slick-active button {
  border-color: #E8620F;
  background-color: #ffffff;
}

/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 840px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
.hero-item.image-height {
  height: 750px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item.image-height {
    height: 650px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.image-height {
    height: 550px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.image-height {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-item.image-height {
    height: 350px;
  }
}
.hero-item.image-height-three {
  height: 540px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item.image-height-three {
    height: 440px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item.image-height-three {
    height: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item.image-height-three {
    height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-item.image-height-three {
    height: 350px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-item {
    height: 650px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-item {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-item {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-item {
    height: 350px;
  }
}

/*-- Hero Content Two For Hero Slider --*/
.hero-content-2.left {
  text-align: left;
}
.hero-content-2.center {
  text-align: center;
}
.hero-content-2.right {
  text-align: right;
}
.hero-content-2 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}
.hero-content-2 h3 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  color: #292929;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-content-2 h3 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-content-2 h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.hero-content-2 h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
  color: #292929;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .hero-content-2 h1 > br {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-content-2 h1 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 h1 {
    font-size: 38px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-content-2 h1 {
    font-size: 30px;
    max-width: 300px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-content-2 h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .hero-content-2 h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
.hero-content-2 a {
  width: 180px;
  text-transform: uppercase;
  border-color: #292929;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-content-2 a {
    width: 170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content-2 a {
    width: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content-2 a {
    width: 160px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-content-2 a {
    width: 150px;
  }
}

/*-- Hero Content Three For Hero Slider --*/
.hero-content-3 {
  display: inline-block;
  text-align: center;
}
.hero-content-3.left {
  text-align: left;
}
.hero-content-3.center {
  text-align: center;
}
.hero-content-3.right {
  text-align: right;
}
.hero-content-3 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}
.hero-content-3 h1 {
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .hero-content-3 h1 {
    font-size: 27px;
  }
}
@media only screen and (max-width: 479px) {
  .hero-content-3 h1 {
    font-size: 22px;
  }
}


.slick-active .hero-content-2 > *, .slick-active .hero-content-3 > * {
  animation-name: fadeInUp;
}
.slick-active .hero-content-2 > *:nth-child(1), .slick-active .hero-content-3 > *:nth-child(1) {
  animation-delay: 0.5s;
}
.slick-active .hero-content-2 > *:nth-child(2), .slick-active .hero-content-3 > *:nth-child(2) {
  animation-delay: 1s;
}
.slick-active .hero-content-2 > *:nth-child(3), .slick-active .hero-content-3 > *:nth-child(3) {
  animation-delay: 1.5s;
}
.slick-active .hero-content-2 > *:nth-child(4), .slick-active .hero-content-3 > *:nth-child(4) {
  animation-delay: 2s;
}
.slick-active .hero-content-2 > *:nth-child(5), .slick-active .hero-content-3 > *:nth-child(5) {
  animation-delay: 2.5s;
}
.slick-active .hero-content-2 > *:nth-child(6), .slick-active .hero-content-3 > *:nth-child(6) {
  animation-delay: 3s;
}

/* --Slider Text Color-- */
.white-text_color .hero-item {
  position: relative;
}
.white-text_color .hero-item:before {
  content: "";
  background-color: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.3;
}
.white-text_color .hero-item .hero-content-2 h1,
.white-text_color .hero-item .hero-content-2 h3,
.white-text_color .hero-item .hero-content-2 a {
  color: #ffffff;
}
.white-text_color .hero-item .hero-content-2 a {
  border-color: #ffffff;
}
.white-text_color .hero-item .hero-content-2 a:hover {
  border-color: #E8620F;
}

/* ---Ginza's Slider Progress Bar--- */
.slider-progress {
  -webkit-animation: initial;
  animation: initial;
  background: rgba(0, 0, 0, 0.3);
  height: 5px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  z-index: 4;
}

.slick-current .slider-progress {
  -webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
  animation: timebar 8s ease-in-out 0s 1 backwards;
}

@-webkit-keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/
.about-us-image a img {
  width: 100%;
}

.about-us-content {
  margin: 50px auto 0px auto;
  max-width: 950px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content {
    margin: 35px auto 0px auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content {
    margin: 35px auto 0px auto;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-content {
    margin: 35px auto 0px auto;
  }
}
.about-us-content h4 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  margin: auto;
  margin-bottom: 35px;
  max-width: 680px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content h4 {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h4 {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-content h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.about-us-content p {
  margin-bottom: 35px;
}

.faq-area h3, .testimonial-area h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  .faq-area h3, .testimonial-area h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

/*----------------------------------------*/
/*  05. Banner CSS
/*----------------------------------------*/
.single-banner-item {
  position: relative;
}

.single-banner-item .banner-image a {
  display: block;
  width: 100%;
}
.single-banner-item .banner-image a img {
  width: 100%;
}
.single-banner-item .banner-image a:hover img {
  opacity: 0.85;
}
.single-banner-item .banner-content {
  position: absolute;
  left: 50px;
  top: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content {
    left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content {
    left: 30px;
    top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content {
    left: 30px;
    top: 30px;
  }
}
.single-banner-item .banner-content .title-light {
  font-size: 19px;
  line-height: 26px;
  color: #333333;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content .title-light {
    font-size: 16px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content .title-light {
    font-size: 16px;
    line-height: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content .title-light {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
  }
}
.single-banner-item .banner-content .title {
  font-size: 22px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 5px;
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content .title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content .title {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content .title {
    font-size: 20px;
    line-height: 23px;
  }
}
.single-banner-item .banner-content a {
  font-size: 14px;
  color: #808080;
  line-height: 1;
  display: inline-block;
  margin-top: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content a {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content a {
    margin-top: 10px;
  }
}
.single-banner-item .banner-content a:hover {
  color: #E8620F;
}
.single-banner-item .banner-content a i {
  font-size: 16px;
  color: #E8620F;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.single-banner-item .banner-content.banner-content-two {
  top: 50%;
  transform: translateY(-50%);
}
.single-banner-item .banner-content.banner-content-two h6 {
  background-color: #FFFFFF;
  padding: 15px;
	opacity: 60%;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.1;
}
.single-banner-item .banner-content.banner-content-two .title-light {
  font-size: 20px;
  line-height: 27px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content.banner-content-two .title-light {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content.banner-content-two .title-light {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-two .title-light {
    font-size: 14px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.banner-content-two .title-light {
    font-size: 16px;
    margin-bottom: 0;
  }
}
.single-banner-item .banner-content.banner-content-two .title {
  font-size: 42px;
  line-height: 46px;
  max-width: 280px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content.banner-content-two .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content.banner-content-two .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-two .title {
    font-size: 30px;
    line-height: 1.2;
    max-width: 160px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.banner-content-two .title {
    font-size: 22px;
    line-height: 1.2;
    max-width: 160px;
    margin-bottom: 0;
  }
}
.single-banner-item .banner-content.banner-content-two a {
  font-size: 18px;
  margin-top: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content.banner-content-two a {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content.banner-content-two a {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-two a {
    margin-top: 10px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.banner-content-two a {
    margin-top: 10px;
    font-size: 14px;
  }
}
.single-banner-item .banner-content.banner-content-two a:hover {
  color: #E8620F;
}
.single-banner-item .banner-content.banner-content-two a i {
  font-size: 16px;
}
.single-banner-item .banner-content.banner-content-three {
  top: 30px;
  left: 20px;
}
.single-banner-item .banner-content.banner-content-three .title-light {
  font-size: 13px;
  line-height: 17px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content.banner-content-three .title-light {
    font-size: 13px;
    line-height: 17px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content.banner-content-three .title-light {
    font-size: 13px;
    line-height: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-three .title-light {
    font-size: 13px;
    line-height: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.banner-content-three .title-light {
    font-size: 13px;
    line-height: 17px;
  }
}
.single-banner-item .banner-content.banner-content-three .title {
  font-size: 19px;
  line-height: 26px;
  font-weight: 600;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-banner-item .banner-content.banner-content-three .title {
    font-size: 19px;
    line-height: 26px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-banner-item .banner-content.banner-content-three .title {
    font-size: 19px;
    line-height: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-three .title {
    font-size: 19px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .single-banner-item .banner-content.banner-content-three .title {
    font-size: 19px;
    line-height: 26px;
  }
}

/* Shop Banner CSS */
.shop-banner img {
  width: 100%;
}

.shop-banner-title h2 {
  font-size: 34px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 37px;
}
@media only screen and (max-width: 767px) {
  .shop-banner-title h2 {
    margin: 0 0 20px;
    font-size: 24px;
  }
}

/*----------------------------------------*/
/*  06. Feature CSS
/*----------------------------------------*/
.single-feature {
  text-align: center;
}
.single-feature.feature-style-two .feature-content .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.single-feature .feature-image {
  margin-bottom: 30px;
}
.single-feature .feature-content .title {
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 25px;
  font-weight: 600;
}
.single-feature .feature-content .short-desc {
  font-size: 13px;
  line-height: 23px;
}
@media only screen and (max-width: 767px) {
  .single-feature .feature-content .short-desc {
    max-width: 250px;
    margin: auto;
  }
}

.feature-section-2 .single-feature {
  display: flex;
  align-items: center;
  text-align: left;
}
@media (max-width: 767px) {
  .feature-section-2 .single-feature {
    display: block;
    text-align: center;
  }
}
.feature-section-2 .single-feature .feature-image {
  margin-bottom: 0;
  width: 25%;
  text-align: center;
}
@media (max-width: 767px) {
  .feature-section-2 .single-feature .feature-image {
    width: 100%;
  }
}
.feature-section-2 .single-feature .feature-content {
  width: 75%;
  padding-left: 15px;
}
@media (max-width: 767px) {
  .feature-section-2 .single-feature .feature-content {
    width: 100%;
    padding: 25px 0 25px 0;
  }
}
.feature-section-2 .single-feature .feature-content > h4.title {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .feature-section-2 [class*=col-]:last-child .single-feature .feature-content {
    padding: 25px 0 0 0 !important;
  }
}

.single-service .service-img {
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .single-service .service-img {
    margin-bottom: 15px;
  }
}
.single-service .service-img img {
  width: 100%;
}
.single-service .service-content .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

/*----------------------------------------*/
/*  07. Product CSS
/*----------------------------------------*/
/* Product Nav Two style */
.product-nav-two .slick-arrow {
  top: auto;
  bottom: 25%;
  margin-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-nav-two .slick-arrow {
    top: 0;
    bottom: auto;
    margin-top: -60px;
  }
}
@media only screen and (max-width: 767px) {
  .product-nav-two .slick-arrow {
    top: 0;
    bottom: auto;
    margin-top: -55px;
  }
}
.product-nav-two .slick-arrow.slick-prev {
  right: auto;
  left: -19%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-nav-two .slick-arrow.slick-prev {
    left: -32%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-nav-two .slick-arrow.slick-prev {
    left: auto;
    right: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .product-nav-two .slick-arrow.slick-prev {
    left: auto;
    right: 50px;
  }
}
.product-nav-two .slick-arrow.slick-next {
  right: auto;
  left: -16%;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-nav-two .slick-arrow.slick-next {
    left: -15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-nav-two .slick-arrow.slick-next {
    left: -27%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-nav-two .slick-arrow.slick-next {
    left: auto;
    right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .product-nav-two .slick-arrow.slick-next {
    left: auto;
    right: 10px;
  }
}

.product-fluid .col {
  border-right: 1px solid #ffffff;
}
.product-fluid .col:last-child {
  border-right: 0;
}

/*-- Product --*/
.single-grid-product {
  transition: 0.6s;
}
.single-grid-product .product-label span {
  position: absolute;
  z-index: 2;
  color: #fff;
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: inline-block;
  top: 20px;
  left: 20px;
}
.single-grid-product .product-label span.new {
  background-color: #E8620F;
}
.single-grid-product .product-label span.sale {
  background-color: #292929;
  left: auto;
  right: 20px;
}
.single-grid-product .product-image {
  position: relative;
  margin-bottom: 20px;
  filter: brightness(98%);
}
.single-grid-product .product-image > Link {
  display: block;
  width: 100%;
}
.single-grid-product .product-image > Link img {
  width: 100%;
  transition: 0.6s;
}
.single-grid-product .product-image > Link img:nth-child(1) {
  visibility: visible;
  opacity: 1;
}
.single-grid-product .product-image > Link img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}
.single-grid-product .product-image .product-action {
  padding: 5px 15px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  margin-right: -50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action {
    padding: 5px 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product .product-image .product-action {
    padding: 5px 8px;
  }
}
.single-grid-product .product-image .product-action .product-btn {
  font-size: 14px;
  font-weight: 600;
  color: #292929;
  text-align: center;
  position: relative;
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-image .product-action .product-btn {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product .product-image .product-action .product-btn {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 767px) {
  .single-grid-product .product-image .product-action .product-btn {
    margin-right: 8px;
  }
}
.single-grid-product .product-image .product-action .product-btn:hover {
  color: #E8620F;
}
.single-grid-product .product-image .product-action ul li {
  padding-right: 17px;
}
.single-grid-product .product-image .product-action ul li a {
  font-size: 18px;
  color: #292929;
  line-height: 45px;
}
.single-grid-product .product-image .product-action ul li a:hover {
  color: #E8620F;
}
.single-grid-product .product-image .product-action ul li:last-child {
  padding-right: 0;
}
.single-grid-product .product-category-rating .category a {
  font-weight: 400;
  line-height: 12px;
  color: #929292;
}
.single-grid-product .product-category-rating .category a:hover {
  color: #292929;
}
.single-grid-product .product-category-rating .rating {
  float: right;
  margin-top: -3px;
}
.single-grid-product .product-category-rating .rating i {
  font-size: 15px;
}
.single-grid-product .product-category-rating .rating i.active {
  color: #F5C60D;
}
.single-grid-product .title {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 10px;
  text-align: center;
}
.single-grid-product .title a {
  display: block;
}
.single-grid-product .title a:hover {
  color: #E8620F;
}
.single-grid-product p {
  font-family: "Karla", sans-serif;
}
.single-grid-product p .main-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #929292;
}
.single-grid-product p .main-price.discounted {
  text-decoration: line-through;
}
.single-grid-product p .discounted-price {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 10px;
  color: #e33;
}
.single-grid-product:hover .product-image > Link img:nth-child(2) {
  visibility: visible;
  opacity: 1;
}
.single-grid-product:hover .product-image .product-action {
  visibility: visible;
  opacity: 1;
  bottom: 20px;
}
.single-grid-product.list-mode {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px;
}
.single-grid-product.list-mode .list-mode-image {
  flex-basis: 100px;
}
.single-grid-product.list-mode .list-mode-content {
  flex-basis: calc(100% - 100px);
  padding-left: 20px;
}
.single-grid-product .product-countdown {
  width: 350px;
  max-width: 100%;
}
.single-grid-product .product-countdown .single-countdown {
  width: 25%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-grid-product .product-countdown .single-countdown {
    padding: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-grid-product .product-countdown .single-countdown {
    padding: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .single-grid-product .product-countdown .single-countdown {
    padding: 10px;
  }
}

/* List Product CSS */
.product-list-item .single-grid-product .product-image {
  margin-bottom: 0;
}

.product-content-shop-list {
  text-align: left;
}
.product-content-shop-list h3 {
  font-size: 14px;
  line-height: 18px;
  margin: 12px 0 20px 0;
}
.product-content-shop-list .product-category-rating {
  margin: 0 0 10px 0;
}
.product-content-shop-list .product-category-rating .review a {
  font-weight: 400;
  line-height: 12px;
  color: #929292;
  margin-left: 10px;
}
.product-content-shop-list .product-category-rating .review a:hover {
  color: #292929;
}
.product-content-shop-list .product-category-rating .rating {
  margin-top: -3px;
}
.product-content-shop-list .product-category-rating .rating i {
  font-size: 15px;
}
.product-content-shop-list .product-category-rating .rating i.active {
  color: #F5C60D;
}
.product-content-shop-list .product-price {
  font-family: "Karla", sans-serif;
  margin: 0 0 35px 0;
}
@media only screen and (max-width: 767px) {
  .product-content-shop-list .product-price {
    margin: 0 0 15px 0;
  }
}
.product-content-shop-list .product-price .main-price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #929292;
}
.product-content-shop-list .product-price .main-price.discounted {
  text-decoration: line-through;
}
.product-content-shop-list .product-price .discounted-price {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 10px;
  color: #e33;
}
.product-content-shop-list .product-desc {
  border-top: 1px solid #ebebeb;
  padding-top: 35px;
}
@media only screen and (max-width: 767px) {
  .product-content-shop-list .product-desc {
    padding-top: 15px;
  }
}

/*-- Product Countdown CSS --*/
.product-details-content .product-countdown {
  margin-bottom: 25px;
}
.product-details-content .product-countdown .single-countdown {
  padding: 15px 25px;
}
@media only screen and (max-width: 767px) {
  .product-details-content .product-countdown .single-countdown {
    padding: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-countdown .single-countdown {
    padding: 15px;
  }
}
.product-details-content .product-countdown .single-countdown .single-countdown-time {
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-countdown .single-countdown .single-countdown-time {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .product-countdown .single-countdown .single-countdown-time {
    font-size: 14px;
  }
}
.product-details-content .product-countdown .single-countdown .single-countdown-text {
  font-size: 10px;
}

.product-countdown .single-countdown {
  background-color: #292929;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  padding: 15px;
  font-weight: 400;
  border-right: 1px solid #ffffff;
  text-align: center;
}
.product-countdown .single-countdown .single-countdown-time {
  display: block;
  font-size: 14px;
}
.product-countdown .single-countdown .single-countdown-text {
  font-size: 10px;
}

/* Shop Toolbar CSS */
.shop-area {
  margin-top: -50px;
  position: relative;
  z-index: 9;
  background: #ffffff;
  margin-left: -15px;
  margin-right: -15px;
  padding: 30px 30px 0 30px;
}
@media only screen and (max-width: 767px) {
  .shop-area {
    padding: 15px 15px 0 15px;
  }
}

.shop-topbar-wrapper {
  position: relative;
  padding: 10px 0;
  margin: 0 0 40px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
@media only screen and (max-width: 575px) {
  .shop-topbar-wrapper {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .grid-list-option {
    flex: 0 0 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .grid-list-option ul {
    justify-content: center;
    margin-bottom: 10px;
  }
}
.grid-list-option ul li {
  margin-right: 12px;
}
.grid-list-option ul li:last-child {
  margin-right: 0;
}
.grid-list-option ul li a {
  font-size: 18px;
  display: block;
  text-align: center;
  color: #292929;
}
.grid-list-option ul li a.active, .grid-list-option ul li a:hover {
  color: #E8620F;
}
.grid-list-option p {
  margin-left: 50px;
}
@media only screen and (max-width: 767px) {
  .grid-list-option p {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 575px) {
  .toolbar-short-area {
    flex: 0 0 100%;
    width: 100%;
  }
}

.toolbar-shorter {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .toolbar-shorter {
    justify-content: center;
    margin-right: 0;
  }
}
@media only screen and (max-width: 575px) {
  .toolbar-shorter {
    justify-content: flex-start;
  }
}
.toolbar-shorter:last-child {
  margin-right: 0;
}
.toolbar-shorter label {
  display: block;
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 14px;
  color: #292929;
  text-transform: capitalize;
}
@media only screen and (max-width: 767px) {
  .toolbar-shorter label {
    margin-right: 10px;
    width: 65px;
  }
}

.nice-select.wide {
  width: 200px;
}
@media only screen and (max-width: 767px) {
  .nice-select.wide {
    width: 165px;
  }
}

.nice-select.small {
  height: 42px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}

/*-- Product Details --*/
.slider-lg-image-1 .lg-image {
  position: relative;
}
.slider-lg-image-1 .lg-image > a {
  width: 100%;
}
.slider-lg-image-1 .lg-image img {
  width: 100%;
}

.slider-thumbs-1 {
  margin-top: 15px;
  overflow: hidden;
}
.slider-thumbs-1 .slick-list {
  margin: 0 -5px;
}
.slider-thumbs-1 .slick-list .slick-slide {
  padding: 0 5px;
}
.slider-thumbs-1 .slick-list .slick-slide > img {
  border: 1px solid transparent;
}
.slider-thumbs-1 .slick-active.slick-current > img {
  border-color: #E8620F;
}

.product-details-thumbs .sm-image {
  cursor: pointer;
}

.product-large-thumb {
  position: relative;
  overflow: hidden;
}

a.popup-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px !important;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #373737;
  color: #fff;
  border-radius: 4px;
  z-index: 9;
}

.product-large-thumb a.popup-img:hover {
  background-color: #E8620F;
  color: #fff;
}

.product-details-content {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .product-details-content {
    margin-top: 30px;
  }
}

.product-nav {
  position: absolute;
  top: 0;
  right: 0;
}
.product-nav > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #d1d1d1;
  line-height: 28px;
  text-align: center;
  border-radius: 3px;
  color: #d1d1d1;
  position: relative;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-nav > a:hover {
  background: #E8620F;
  border-color: #E8620F;
  color: #fff;
}

.product-details-content.text-center h2 {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .product-details-content.text-center h2 {
    max-width: 245px;
    font-size: 18px;
    margin: auto;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .product-details-content.text-center h2 {
    max-width: 195px;
    font-size: 17px;
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
  }
}
.product-details-content h2 {
  color: #292929;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 10px;
  max-width: 400px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h2 {
    max-width: 370px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h2 {
    font-size: 17px;
    max-width: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .product-details-content h2 {
    font-size: 18px;
    max-width: 300px;
  }
}
@media only screen and (max-width: 479px) {
  .product-details-content h2 {
    font-size: 18px;
    max-width: 175px;
  }
}

.single-product-reviews {
  font-size: 15px;
  margin-bottom: 20px;
}
.single-product-reviews i.active {
  color: #F5C60D;
}

.review-link {
  font-size: 13px;
  color: #292929;
  margin-left: 10px;
  line-height: 30px;
  display: inline-block;
}
.review-link:hover {
  color: #E8620F;
}

.single-product-price {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}
.single-product-price .price.new-price {
  color: #E8620F;
  font-weight: 500;
}
.single-product-price .regular-price {
  text-decoration: line-through;
}

.product-description p {
  line-height: 25px;
  color: #929292;
  margin-bottom: 30px;
}

.single-product-quantity {
  margin-bottom: 35px;
}
.single-product-quantity .product-quantity {
  background: #fff;
  border: 1px solid #ededed;
  float: left;
  margin-right: 15px;
  padding: 0 10px;
  border-radius: 0;
}
.single-product-quantity .product-quantity input {
  background: none;
  border: none;
  color: #292929;
  font-size: 15px;
  font-weight: 400;
  height: 55px;
  line-height: 53px;
  padding: 0;
  width: 80px;
}
@media only screen and (max-width: 767px) {
  .single-product-quantity .product-quantity input {
    height: 45px;
    width: 40px;
  }
}
.single-product-quantity .btn {
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .single-product-quantity .btn {
    padding: 8px 15px;
  }
}
.single-product-quantity .btn i {
  font-size: 20px;
  margin-right: 10px;
}

.wishlist-compare-btn {
  margin-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  .wishlist-compare-btn {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .wishlist-compare-btn {
    text-align: left;
  }
}
@media only screen and (max-width: 479px) {
  .wishlist-compare-btn {
    text-align: center;
  }
}
.wishlist-compare-btn a {
  background: none;
  border: 1px solid #ededed;
  color: #292929;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  height: 45px;
  line-height: 43px;
  text-align: center;
  width: 170px;
  border-radius: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-compare-btn a {
    width: 155px;
  }
}
.wishlist-compare-btn a.wishlist-btn {
  margin-right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-compare-btn a.wishlist-btn {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .wishlist-compare-btn a.wishlist-btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.wishlist-compare-btn a.add-compare {
  border-radius: 5px;
}
.wishlist-compare-btn a.wishlist-btn:before {
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: top;
}
.wishlist-compare-btn a:hover {
  background: #E8620F;
  border-color: #E8620F;
  color: #fff;
}
.wishlist-compare-btn a:hover:before {
  -webkit-animation: crFlipX 2s ease-in-out infinite both;
  animation: crFlipX 2s ease-in-out infinite both;
}

.product-meta {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  padding: 15px 0;
  width: 100%;
}
.product-meta .posted-in {
  color: #292929;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  text-transform: uppercase;
}
.product-meta .posted-in > a {
  color: #292929;
  font-weight: 400;
  text-transform: none;
}
.product-meta .posted-in > a:hover {
  color: #E8620F;
}

.single-product-sharing {
  margin-bottom: 0;
  padding: 15px 0;
  width: 100%;
}
.single-product-sharing > h3 {
  color: #292929;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
  text-transform: uppercase;
}
.single-product-sharing ul {
  display: inline-block;
  margin-bottom: 10px;
}
.single-product-sharing ul > li {
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  margin-right: 15px;
  position: relative;
}

.single-product-sharing ul li a {
  color: #555555;
  display: block;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}
.single-product-sharing ul li a:hover {
  color: #E8620F;
}

/*Single Product variable css*/
.product-variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.product-variants > .product-variants-item {
  margin: 20px 0;
  display: block;
  margin-top: 0;
}
.product-variants > .product-variants-item:last-child {
  margin-left: 30px;
}

.product-variants-item span.control-label {
  margin-bottom: 6px;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  text-transform: capitalize;
}

.product-variants .product-variants-item select {
  border: 1px solid #ebebeb;
  height: 40px;
  width: 60px;
  background: #fff;
  border: 1px solid #ebebeb;
  height: 40px;
  width: 60px;
  background: #fff;
}

.procuct-color > li {
  display: inline-block;
  list-style: none;
  margin-left: 5px;
}
.procuct-color > li:nth-child(2) > a > span.color {
  background: #00EEB3;
}
.procuct-color > li > a > span.color {
  background: #ffa07a;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.procuct-color > li.active > a > span.color, .procuct-color > li:hover > a > span.color {
  outline: 1px solid #D6B98C;
  outline-offset: 1px;
}

/*single product group css*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .group-cart-table {
    margin-bottom: 20px;
  }
}
.group-cart-table .table {
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .group-cart-table .table {
    margin-bottom: 15px;
  }
}
.group-cart-table .table td {
  text-align: center;
  vertical-align: middle;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table td {
    min-width: 150px;
  }
}
@media only screen and (max-width: 575px) {
  .group-cart-table .table td {
    min-width: 180px;
  }
}
.group-cart-table .quantity-field label {
  margin-right: 5px;
}
.group-cart-table .quantity-field input {
  width: 50px;
  text-align: center;
  border: 1px solid #ddd;
  height: 45px;
}

/*Single Product Tab Style 2 CSS*/
.single-product-area .slider-thumbs-2 {
  padding: 40px 0px;
}

.product-details-images-2 {
  float: right;
  width: calc(100% - 110px);
  padding-left: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-images-2 {
    width: calc(100% - 80px);
  }
}
@media only screen and (max-width: 767px) {
  .product-details-images-2 {
    width: calc(100% - 80px);
  }
}

.product-details-thumbs-2 {
  float: left;
  width: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-thumbs-2 {
    width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-thumbs-2 {
    width: 80px;
  }
}

/*Single Product Tab Style 3 CSS*/
.product-details-images-2.tabstyle-3 {
  float: left;
  width: calc(100% - 110px);
  padding-right: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-images-2.tabstyle-3 {
    width: calc(100% - 80px);
  }
}
@media only screen and (max-width: 767px) {
  .product-details-images-2.tabstyle-3 {
    width: calc(100% - 80px);
  }
}

.product-details-thumbs-2.tabstyle-3 {
  float: right;
  width: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-thumbs-2.tabstyle-3 {
    width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-thumbs-2.tabstyle-3 {
    width: 80px;
  }
}

/*Single Product gallery Style CSS*/
.lg-image.col-50 {
  float: left;
  width: 50%;
  padding: 5px;
}
.lg-image.col-50 a.popup-img {
  bottom: 5px;
  right: 5px;
}

/*Single Product sticky Style CSS*/
.lg-image.sidebar-sticky {
  margin-bottom: 20px;
}
.lg-image.sidebar-sticky:last-child {
  margin-bottom: 0;
}

/*Single Product slider box Style CSS*/
.slider-box {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .slider-box {
    margin-bottom: 0;
  }
}
.slider-box .slick-list {
  margin: 0 -10px;
}
.slider-box .slick-list .slick-slide {
  padding: 0 10px;
}

.slider-box-content .product-description p {
  max-width: 80%;
  margin: auto;
  margin-bottom: 15px;
}
.slider-box-content .product-quantity {
  float: none;
  display: inline-block;
}
.slider-box-content .add-to-link {
  display: inline-block;
}

/*-- Product Details Tab List --*/
.dec-and-review-menu {
  justify-content: center;
}
.dec-and-review-menu > li {
  list-style: none;
  position: relative;
  z-index: 9;
  display: inline-block;
  margin-right: 50px;
}
@media only screen and (max-width: 575px) {
  .dec-and-review-menu > li {
    margin-bottom: 10px;
    margin-right: 0;
    text-align: center;
    display: block;
    width: 100%;
  }
}
.dec-and-review-menu > li:last-child {
  margin-right: 0;
}
.dec-and-review-menu > li > a {
  display: block;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}
.dec-and-review-menu > li > a.active, .dec-and-review-menu > li > a:hover {
  color: #292929;
}

/*-- Product Details Tab Content --*/
.product-review-tab {
  padding: 100px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-review-tab {
    padding: 80px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-review-tab {
    padding: 70px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-review-tab {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 767px) {
  .product-review-tab {
    padding: 50px 0;
  }
}

.product-review-content-tab {
  padding-top: 40px;
  font-size: 13px;
  line-height: 26px;
  width: 100%;
}

.single-product-description p {
  line-height: 22px;
  color: #929292;
}

.review-page-comment h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.review-page-comment > ul {
  margin: 0 0 25px;
}
.review-page-comment > ul > li {
  margin: 0 0 20px;
  position: relative;
  list-style: none;
}

.product-comment img {
  width: 60px;
  float: left;
}

.product-comment-content {
  border: 1px solid #ededed;
  border-radius: 3px;
  margin-left: 80px;
  position: relative;
  padding: 15px 15px 0;
}
.product-comment-content:before {
  background: #fff;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  content: "";
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.meta > strong {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 31px;
}
.meta > span {
  font-size: 13px;
  font-weight: 400;
  line-height: 31px;
}

.description p {
  font-size: 13px;
  color: #929292;
  line-height: 28px;
}

.comment-notes {
  margin: 0;
}

.rating i.fa:not(:last-child) {
  color: #F9BA48;
}

.comment-form-comment {
  margin-bottom: 10px;
}
.comment-form-comment textarea {
  border: 1px solid #ededed;
  border-radius: 0;
  height: 125px;
  padding: 10px;
  width: 100%;
  background: #f5f5f5;
}

.review-comment-form-author, .review-comment-form-email {
  margin-bottom: 10px;
}

.review-comment-form-author input, .review-comment-form-email input {
  background: #f5f5f5;
  border: none;
  border-radius: 0;
  height: 38px;
  line-height: 38px;
  max-width: 100%;
  width: 300px;
  display: block;
  padding: 0 0 0 10px;
  vertical-align: middle;
}

.review-comment-form-author input:focus, .review-comment-form-email input:focus, .comment-form-comment textarea:focus {
  outline: auto;
  outline-color: #E8620F;
}

.form-button {
  border: none !important;
  height: 42px !important;
  line-height: 40px !important;
  background: #E8620F;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 38px;
  letter-spacing: 0.1em;
  line-height: 38px;
  overflow: hidden;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
}
.form-button:hover {
  background: #222;
  color: #fff;
}

/*---------------------------------------
    08. Cart CSS
-----------------------------------------*/
/*-- Cart Table --*/
.cart-table .table {
  margin: 0;
}
@media only screen and (max-width: 767px) {
 
}
.cart-table .table thead {
  background-color: #292929;
}
@media only screen and (max-width: 767px) {
  .cart-table .table thead {
    display: none;
  }
}
.cart-table .table thead tr th {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 12px 20px;
}
.cart-table .table tbody tr td {
  text-align: center;
  padding: 25px 20px;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .cart-table .table tbody tr td {
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px;
    text-align: left;
  }
}

.cart-table th.pro-thumbnail, .cart-table td.pro-thumbnail {
  max-width: 250px;
  min-width: 100px;
  width: 250px;
}
@media only screen and (max-width: 767px) {
  .cart-table th.pro-thumbnail a, .cart-table td.pro-thumbnail a {
    width: 120px;
  }
}
.cart-table th.pro-title, .cart-table td.pro-title {
  min-width: 200px;
}
.cart-table td.pro-thumbnail a {
  display: block;
}
.cart-table td.pro-thumbnail a img {
  width: 100%;
	height: 100%;
  background-color: #f6f7f8;
}
.cart-table td.pro-title {
  font-size: 16px;
  font-weight: 600;
  color: #929292;
}
.cart-table td.pro-title a:hover {
  color: #E8620F;
}
.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #929292;
}
.cart-table td.pro-quantity .pro-qty {
  display: inline-flex;
  text-align: center;
}
.cart-table td.pro-quantity .pro-qty .qtybtn {
  height: 42px;
  padding: 0 10px;
  background-color: transparent;
}
.cart-table td.pro-quantity .pro-qty input {
  height: 42px;
  width: 50px;
  text-align: center;
color: #929292;
}
.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #929292;
}
.cart-table td.pro-addtocart button {
  height: 44px;
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .cart-table td.pro-addtocart button {
    line-height: 22px;
  }
}
.cart-table td.pro-stock span.in-stock {
  font-size: 14px;
  text-transform: capitalize;
  color: #E8620F;
}
.cart-table td.pro-stock span.out-stock {
  color: #E8620F;
}
.cart-table td.pro-remove a {
  display: block;
  font-weight: 600;
  color: #929292;
}
.cart-table td.pro-remove a i {
  font-size: 25px;
}
.cart-table td.pro-remove a:hover {
  color: #E8620F;
}
@media only screen and (max-width: 767px) {
  .cart-table td.pro-remove a {
    width: 60px;
    text-align: center;
  }
}

/*-- Calculate Shipping --*/
.calculate-shipping {
  margin-bottom: 23px;
}
.calculate-shipping h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}
.calculate-shipping form .nice-select {
  width: 100%;
  border-radius: 0;
  height: 55px;
  border: 1px solid #999999;
  line-height: 40px;
  padding: 9px 20px;
  color: #929292;
  background-color: transparent;
}
.calculate-shipping form input {
  width: 100%;
  height: 55px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #929292;
  color: #929292;
  background-color: transparent;
}
.calculate-shipping form input[type=submit] {
  font-weight: 700;
  color: #292929;
  background-color: #E8620F;
  border-color: #E8620F;
  width: 140px;
}
.calculate-shipping form input[type=submit]:hover {
  background-color: #292929;
  border-color: #292929;
  color: #E8620F;
}

/*-- Discount Coupon --*/
.discount-coupon h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}
.discount-coupon form input {
  width: 100%;
  height: 55px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 9px 20px;
  color: #929292;
  background-color: transparent;
}
.discount-coupon form input[type=submit] {
  font-weight: 700;
  color: #292929;
  background-color: #E8620F;
  border-color: #E8620F;
  width: 140px;
}
.discount-coupon form input[type=submit]:hover {
  background-color: #292929;
  border-color: #292929;
  color: #E8620F;
}

/*-- Cart Summary --*/
.cart-summary {
   width: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .cart-summary {
    margin-left: 0;
    max-width: 100%;
  }
}
.cart-summary .cart-summary-wrap {
  background-color: #f5f5f5;
  padding: 25px 30px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-wrap {
    padding: 30px 30px;
  }
}
.cart-summary .cart-summary-wrap h4 {
  font-size: 20px;
  line-height: 13px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #292929;
}
.cart-summary .cart-summary-wrap h3 {
  font-size: 18px;
  color: #292929;
}
.cart-summary .cart-summary-wrap p {
  font-weight: 600;
  line-height: 13px;
  color: #292929;
}
.cart-summary .cart-summary-wrap p span {
  float: right;
}
.cart-summary .cart-summary-wrap h2 {
  border-top: 1px solid #292929;
  padding-top: 9px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  color: #292929;
  margin: 0;
}
.cart-summary .cart-summary-wrap h2 span {
  float: right;
}
.cart-summary .cart-summary-button {
  overflow: hidden;
  width: 300px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}
.cart-summary .cart-summary-button button, .FicheTechnique { 
  width: 300px;
  border-radius: 0;
  height: 44px;
  line-height: 24px;
  padding: 9px 20px;
  margin-bottom: 10px;
  float:left;
}
.FicheTechnique{
  margin-top: 5px;
}
.cart-summary .cart-summary-button button:last-child, .FicheTechnique:last-child {
  margin-left: 0;
}
.cart-summary .cart-summary-button button.checkout-btn, .FicheTechnique.ckeckout-btn {
  color: #292929;
  background-color: #E8620F;
  border-color: #E8620F;
}
.cart-summary .cart-summary-button button:hover, .FicheTechnique:hover {
  background-color: #E8620F;
  border-color: #E8620F;
  color: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 20px;
  }
  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button button {
    margin-left: 0;
    margin-right: 10px;
  }
  .cart-summary .cart-summary-button button:last-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button button {
    width: 270px;
  }
}

/*---------------------------------------
    09. Checkout CSS
-----------------------------------------*/
/*-- Checkout Title --*/
.checkout-title {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}

/*-- Checkout Form --*/
.checkout-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.checkout-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #929292;
  margin-bottom: 15px;
}
.checkout-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #929292;
  margin-bottom: 15px;
}
.checkout-form input[type=checkbox] {
  width: auto;
}
.checkout-form .check-box {
  float: left;
  margin-right: 70px;
}
@media only screen and (max-width: 575px) {
  .checkout-form .check-box {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .checkout-form .check-box {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.checkout-form .check-box:last-child {
  margin-right: 0;
}
.checkout-form .check-box input[type=checkbox] {
  display: none;
}
.checkout-form .check-box input[type=checkbox] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  margin: 0;
}
.checkout-form .check-box input[type=checkbox] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #999999;
  content: "";
  transition: all 0.3s ease 0s;
}
.checkout-form .check-box input[type=checkbox] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #292929;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.checkout-form .check-box input[type=checkbox]:checked + label::before {
  border: 2px solid #292929;
}
.checkout-form .check-box input[type=checkbox]:checked + label::after {
  opacity: 1;
}

/*-- Shipping Form --*/
#shipping-form {
  display: none;
}

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  background-color: #f5f5f5;
  padding: 45px;
}
@media only screen and (max-width: 575px) {
  .checkout-cart-total {
    padding: 30px;
  }
}
.checkout-cart-total h4 {
  flex-basis: 18px;
  line-height: 23px;
  font-weight: 600;
  color: #292929;
}
.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}
.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}
.checkout-cart-total h4 span {
  float: right;
  display: block;
}
.checkout-cart-total ul {
  border-bottom: 1px solid #292929;
}
.checkout-cart-total ul li {
  color: #292929;
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
}
.checkout-cart-total ul li span {
  color: #292929;
  float: right;
}
.checkout-cart-total p {
  line-height: 30px;
  font-weight: 600;
  color: #292929;
  padding: 10px 0;
  border-bottom: 1px solid #292929;
  margin: 0;
}
.checkout-cart-total p span {
  float: right;
}

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  background-color: #f5f5f5;
  padding: 45px;
}
@media only screen and (max-width: 575px) {
  .checkout-payment-method {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px;
}
.single-method:last-child {
  margin-bottom: 0;
}
.single-method input[type=radio] {
  display: none;
}
.single-method input[type=radio] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  margin: 0;
}
.single-method input[type=radio] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #292929;
  content: "";
  transition: all 0.3s ease 0s;
}
.single-method input[type=radio] + label::after {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #E8620F;
  width: 10px;
  text-align: center;
  height: 10px;
  transition: all 0.3s ease 0s;
}
.single-method input[type=radio]:checked + label::before {
  border: 2px solid #E8620F;
}
.single-method input[type=radio]:checked + label::after {
  opacity: 1;
}
.single-method input[type=checkbox] {
  display: none;
}
.single-method input[type=checkbox] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  margin: 0;
}
.single-method input[type=checkbox] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #292929;
  content: "";
  transition: all 0.3s ease 0s;
}
.single-method input[type=checkbox] + label::after {
  position: absolute;
  left: 4px;
  top: 4px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #E8620F;
  width: 8px;
  text-align: center;
  height: 8px;
  transition: all 0.3s ease 0s;
}
.single-method input[type=checkbox]:checked + label::before {
  border: 2px solid #E8620F;
}
.single-method input[type=checkbox]:checked + label::after {
  opacity: 1;
}
.single-method p {
  display: none;
  margin-top: 8px;
  color: #292929;
  line-height: 23px;
}

/*-- Place Order --*/
.place-order {
  margin-top: 40px;
  float: left;
}

/*---------------------------------------
    10. Compare CSS
-----------------------------------------*/
/*-- Compare Table --*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}
.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}
.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}
.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 18px;
  font-weight: 600;
  color: #292929;
  margin: 0;
  line-height: 1;
}
.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}
.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  display: block;
}
.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}
.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  font-size: 13px;
  line-height: 15px;
  color: #444444;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.compare-table .table tbody tr td.product-image-title .category:hover {
  color: #E8620F;
}
.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  line-height: 20px;
  margin-bottom: 10px;
}
.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #E8620F;
}
.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}
.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
}
.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #E8620F;
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 25px 10px 25px;
  transition: all 0.3s ease-in-out;
}
.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  background-color: #222;
}
.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 26px;
  overflow: hidden;
  display: block;
  text-align: center;
}
.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added {
  padding-left: 25px;
  padding-right: 56px;
}
.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added i {
  left: calc(100% - 41px);
}
.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.compare-table .table tbody tr td.pro-remove button:hover {
  color: #E8620F;
}
.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}
.compare-table .table tbody tr td.pro-ratting i {
  color: #444444;
  font-size: 14px;
}

/*----------------------------------------*/
/*  11. Faq CSS
/*----------------------------------------*/
.faq-desc {
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-desc {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .faq-desc {
    margin-bottom: 30px;
  }
}
.faq-desc h3 {
  color: #444;
  font-size: 18px;
  font-weight: 700;
}
.faq-desc p {
  color: #929292;
  margin: 0;
}

.faq-accordion .card {
  border-radius: 0;
  margin-bottom: 10px;
}
.faq-accordion .card.actives {
  border: 1px solid #E8620F;
}
.faq-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f5f5f5;
  border: 0;
}
.faq-accordion .card-header a {
  display: block;
  padding: 15px 10px 15px 25px;
  cursor: pointer;
}
.faq-accordion .card-header a.collapsed:before {
  content: "";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
}
.faq-accordion .card-header a:after {
  content: "";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
  content: "";
}
@media only screen and (max-width: 767px) {
  .faq-accordion .card-header a {
    font-size: 14px;
    padding-right: 30px;
  }
}
.faq-accordion .card-header a:hover {
  color: #E8620F !important;
}

/* Faq Style Two CSS */
.faq-accordion .card-style-two {
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0;
}
.faq-accordion .card-style-two.actives {
  border: 0;
}
.faq-accordion .card-style-two.actives .card-header {
  background-color: #E8620F;
  border-color: #E8620F;
}
.faq-accordion .card-style-two.actives .card-header a {
  color: #ffffff;
}
.faq-accordion .card-style-two .card-header {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0;
  text-decoration: none;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
}
.faq-accordion .card-style-two .card-header:hover {
  background-color: #E8620F;
  border-color: #E8620F;
}
.faq-accordion .card-style-two .card-header a {
  display: block;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 22px;
  font-weight: 500;
  color: #292929;
}
@media only screen and (max-width: 767px) {
  .faq-accordion .card-style-two .card-header a {
    font-size: 13px;
  }
}
.faq-accordion .card-style-two .card-header a.collapsed::before {
  font-size: 18px;
  content: "";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
}
.faq-accordion .card-style-two .card-header a.collapsed::after {
  display: none;
}
.faq-accordion .card-style-two .card-header a:after {
  font-size: 18px;
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 12px;
  content: "";
}
@media only screen and (max-width: 767px) {
  .faq-accordion .card-style-two .card-header a {
    padding: 15px 15px;
  }
}
.faq-accordion .card-style-two .card-header a:hover {
  color: #ffffff;
}
.faq-accordion .card-style-two .card-header a:hover {
  color: #ffffff !important;
}
.faq-accordion .card-body {
  border: 1px solid #ebebeb;
}

/*---------------------------------------
    12. Blog CSS
-----------------------------------------*/
/*-- Blog Slider --*/
/*-- Blog --*/
.blog .blog-inner .media, .blog .blog-inner .tf-element-carousel {
  display: block;
  position: relative;
  overflow: hidden;
}
.blog .blog-inner .media .image, .blog .blog-inner .tf-element-carousel .image {
  display: block;
}
.blog .blog-inner .media .image img, .blog .blog-inner .tf-element-carousel .image img {
  width: 100% !important;
  transition: all 1s ease-out;
}
.blog .blog-inner .blog-audio iframe {
  width: 100%;
  height: 242px;
  border: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .blog-audio iframe {
    height: 190px;
  }
}
.blog .blog-inner .blog-video iframe {
  width: 100%;
  height: 242px;
  border: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog .blog-inner .blog-video iframe {
    height: 190px;
  }
}
.blog .blog-inner .content {
  padding: 20px 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog .blog-inner .content {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .blog .blog-inner .content {
    padding-bottom: 0;
  }
}
.blog .blog-inner .content .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.blog .blog-inner .content .meta li {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
}
.blog .blog-inner .content .meta li a {
  color: #E8620F;
  text-transform: lowercase;
}
.blog .blog-inner .content .meta li::after {
  content: "-";
  margin: 0 10px;
}
.blog .blog-inner .content .meta li:last-child::after {
  display: none;
}
.blog .blog-inner .content .meta li .date-time {
  padding-right: 8px;
}
.blog .blog-inner .content .meta li .date-time .date {
  padding: 0 8px;
}
.blog .blog-inner .content .meta li .date-time .separator {
  padding-right: 8px;
}
.blog .blog-inner .content .meta li .date-time .month {
  padding-right: 8px;
}
.blog .blog-inner .content .title {
  font-size: 17px;
  font-weight: 500;
  color: #292929;
  line-height: 23px;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.blog .blog-inner .content p {
  padding: 15px 0 18px;
  margin: 0;
  line-height: 23px;
  color: #929292;
}
.blog .blog-inner .content > a {
  color: #ffffff;
  border-color: #292929;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  line-height: 40px;
  margin-top: 15px;
  min-width: 117px;
  padding: 0 20px;
}
.blog .blog-inner .content > a:hover {
  border-color: #E8620F;
  background-color: #E8620F;
  color: #ffffff;
}
.blog:hover .blog-inner .media .image img {
  transform: scale(1.1, 1.1);
}

.blog-list .blog-image {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .blog-list .blog-image {
    margin-bottom: 20px;
  }
}
.blog-list .blog-image a img {
  width: 100%;
}
.blog-list .blog-image .post-category {
  bottom: 10px;
  left: 10px;
  position: absolute;
  z-index: 109;
}
.blog-list .blog-image .post-category a {
  background: #fff;
  color: #242424;
  display: inline-block;
  margin: 0 6px 6px 0;
  padding: 4px 12px;
  border-radius: 4px;
}
.blog-list .blog-image .post-category a:hover {
  background-color: #E8620F;
  color: #ffffff;
}
.blog-list .blog-image .blog-audio iframe {
  width: 100%;
  height: 242px;
  border: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-list .blog-image .blog-audio iframe {
    height: 190px;
  }
}
.blog-list .blog-image .blog-video iframe {
  width: 100%;
  height: 242px;
  border: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-list .blog-image .blog-video iframe {
    height: 190px;
  }
}
.blog-list .blog-content .title {
  font-size: 26px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-list .blog-content .title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-list .blog-content .title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-list .blog-content .title {
    font-size: 22px;
  }
}
.blog-list .blog-content .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.blog-list .blog-content .meta li {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
}
.blog-list .blog-content .meta li a {
  color: #E8620F;
  text-transform: lowercase;
}
.blog-list .blog-content .meta li::after {
  content: "-";
  margin: 0 10px;
}
.blog-list .blog-content .meta li:last-child::after {
  display: none;
}
.blog-list .blog-content .meta li .date-time {
  padding-right: 8px;
}
.blog-list .blog-content .meta li .date-time .date {
  padding: 0 8px;
}
.blog-list .blog-content .meta li .date-time .separator {
  padding-right: 8px;
}
.blog-list .blog-content .meta li .date-time .month {
  padding-right: 8px;
}
.blog-list .blog-content > a {
  border-color: #292929;
  height: 44px;
  padding: 0 20px;
  min-width: 117px;
  line-height: 40px;
}
.blog-list .blog-content > a:hover {
  border-color: #E8620F;
}

/*-- Blog Details CSS --*/
.blog_area article.blog_single {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 50px;
  padding-bottom: 20px;
  text-align: center;
}

.blog_single header.entry-header {
  margin-bottom: 30px;
}

article.blog_single .post-category {
  color: #E8620F;
  display: block;
  line-height: 25px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
article.blog_single .post-category > a {
  color: #E8620F;
}
article.blog_single .post-category > a:hover {
  color: #292929;
}

.blog_single header h2 {
  margin-bottom: 14px;
}

h2.entry-title {
  color: #444;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
h2.entry-title:before {
  content: "";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 8px;
  margin-right: 5px;
  vertical-align: top;
}

.post-author {
  color: #8c8c8c;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
}

.post-separator {
  color: #8c8c8c;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
}

.post-date {
  color: #8c8c8c;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
}

.blog_single .post-thumbnail {
  margin-bottom: 26px;
}
.blog_single .blog-audio, .blog_single .blog-video {
  margin-bottom: 26px;
}
.blog_single .blog-audio iframe, .blog_single .blog-video iframe {
  width: 100%;
  height: 500px !important;
  border: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_single .blog-audio iframe, .blog_single .blog-video iframe {
    height: 350px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_single .blog-audio iframe, .blog_single .blog-video iframe {
    height: 350px !important;
  }
}
@media only screen and (max-width: 767px) {
  .blog_single .blog-audio iframe, .blog_single .blog-video iframe {
    height: 250px !important;
  }
}
.blog_single .post-gallery {
  margin-bottom: 26px;
}
.blog_single .post-gallery img {
  width: 100%;
}

.blog_area article.blog_single {
  text-align: center;
}

.post-info .entry-summary > p {
  margin-bottom: 27px;
}

.blog_single .readmore.button {
  background: #434343 none repeat scroll 0 0;
  border-color: #434343;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
}

.social-sharing .widget-title {
  background: none;
  color: #929292;
  font-size: 12px;
  line-height: 26px;
  margin: 0 20px;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 30px;
  font-weight: 600;
}

.blog-social-icons {
  margin: 10px 0 20px;
}
.blog-social-icons > li {
  list-style: none;
  display: inline-block;
}
.blog-social-icons > li > a {
  background: #f7f7f7;
  border-radius: 100%;
  color: #353535;
  display: block;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
  text-align: center;
  width: 26px;
}
.blog-social-icons > li > a:hover {
  background-color: #E8620F;
  color: #fff;
}

.blog_area .blog_single.blog-details {
  border-bottom: none;
  margin-bottom: 15px;
}

.entry-summary.blog-post-description > p {
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 25px;
  text-align: left;
}
.entry-summary.blog-post-description blockquote {
  background: #f6f6f6;
  border: 1px solid #ededed;
  border-left: 5px solid #E8620F;
  font-size: 15px;
  font-style: italic;
  line-height: 26px;
  margin: 40px 0 40px 40px;
  padding: 30px 45px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .entry-summary.blog-post-description blockquote {
    margin: 20px 0 20px 0px;
    padding: 20px 25px;
  }
}

.single-post-tag {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0;
  margin: 30px 0;
  text-align: left;
}
.single-post-tag > a {
  font-size: 13px;
  text-transform: uppercase;
}
.single-post-tag > a:hover {
  color: #E8620F;
}

.relatedposts > h3 {
  display: inline-block;
  font-size: 20px;
  margin: 0 0 30px;
  font-weight: 600;
  padding: 0 0 20px;
  position: relative;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .relatedposts > h3 {
    margin: 0 0 15px;
    padding: 0 0 10px;
  }
}

.relatedthumb > h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #444;
  text-transform: uppercase;
  margin: 0;
}

.relatedthumb .image {
  margin-bottom: 25px;
}
.relatedthumb .image a {
  display: block;
}
.relatedthumb .image a img {
  width: 100%;
}

.rl-post-date {
  color: #8c8c8c;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  font-style: italic;
}

.comments-area > h3, .comment-box > h3 {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.comments-area ol {
  list-style: none;
  padding-left: 0;
}
.comments-area > ol > li > ol {
  margin-left: 30px;
}
.comments-area .commentlist li .single-comment {
  background: #fff;
  border: 1px solid #ededed;
  margin: 0 0 20px;
  padding: 30px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .comments-area .commentlist li .single-comment {
    padding: 15px;
  }
}
.comments-area .commentlist li .comment-avatar {
  float: left;
  margin-right: 20px;
}
.comments-area .commentlist li .comment-info {
  border: none;
  overflow: hidden;
  position: relative;
}
.comments-area .commentlist .comment-info a {
  color: #292929;
  display: block;
  font-weight: 600;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .comments-area .commentlist .comment-info a {
    margin-bottom: 10px;
    display: block;
  }
}
.comments-area .commentlist li .comment-info .reply {
  display: inline-block;
  /* float: right; */
  position: absolute;
  right: 0;
  top: 0;
}
.comments-area .commentlist li .comment-info .reply a {
  background: none;
  border: 1px solid #E8620F;
  box-shadow: none;
  color: #E8620F;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  height: 28px;
  line-height: 26px;
  padding: 0 20px;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  white-space: nowrap;
  border-radius: 500px;
}
.comments-area .commentlist li .comment-info .reply a:hover {
  background-color: #E8620F;
  color: #fff;
}
.comments-area .commentlist .comment-info span.date {
  display: block;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 15px;
  line-height: 19px;
}

.comment-info > p {
  margin-bottom: 0;
}

.comment-note {
  line-height: 25px;
  margin-bottom: 10px;
}

.single-input {
  margin-bottom: 20px;
}
.single-input > label {
  line-height: 25px;
}
.single-input > textarea {
  border: none;
  background: #f2f2f2;
  border-radius: 0;
  height: 135px;
  padding: 15px;
  width: 100%;
}
.single-input > input {
  background: #f2f2f2;
  border: none;
  height: 46px;
  padding-left: 15px;
  width: 100%;
}
.single-input > input:focus {
  outline: auto;
  outline-color: #E8620F;
}
.single-input > textarea:focus {
  outline: auto;
  outline-color: #E8620F;
}

/*---------------------------------------
    13. Login Register CSS
-----------------------------------------*/
.form-login-title > h2, .form-register-title > h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 26px;
}
@media only screen and (max-width: 767px) {
  .form-login-title > h2, .form-register-title > h2 {
    font-size: 24px;
    line-height: 20px;
  }
}

.login-form, .register-form {
  padding: 20px;
  border: 1px solid #d3ced2;
  margin: 30px 0;
  text-align: left;
  border-radius: 5px;
}

.form-fild, .login-submit, .lost-password, .register-submit {
  margin-bottom: 10px;
}

.login-submit label {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .login-submit label {
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.form-fild p {
  margin: 0;
}
.form-fild p span.required {
  color: #f23838;
}
.form-fild input {
  width: 100%;
  padding: 0 15px;
  height: 45px;
  border: 1px solid #dddddd;
  color: #929292;
  border-radius: 5px;
}

.lost-password > a {
  color: #a43d21;
}
.lost-password > a:hover {
  color: #E8620F;
}

/*---------------------------------------
    16. My Account CSS
-----------------------------------------*/
.myaccount-tab-menu {
  flex-direction: column;
  background-color: #ffffff;
}
.myaccount-tab-menu a {
  border: 1px solid #eeeeee;
  border-bottom: none;
  color: #929292;
  font-weight: 500;
  font-size: 12px;
  display: block;
  padding: 15px 15px 13px;
  text-transform: uppercase;
}
.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #eeeeee;
}
.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #E8620F;
  color: #ffffff;
}
.myaccount-tab-menu a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

/*-- My Account Content -*/
.myaccount-content {
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #eeeeee;
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}
.myaccount-content h3 {
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.myaccount-content .welcome a {
  color: #000000;
}
.myaccount-content .welcome a:hover {
  color: #E8620F;
}
.myaccount-content .welcome strong {
  font-weight: 600;
}
.myaccount-content a.edit-address-btn {
  border-color: #292929;
}
.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}
.myaccount-content a.edit-address-btn:hover {
  background-color: #E8620F;
  border-color: #E8620F;
}
.myaccount-content button.save-change-btn {
  background: none;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #292929;
  border-color: #292929;
  width: 140px;
  padding: 10px 0;
  border-radius: 3px;
}
.myaccount-content button.save-change-btn:hover {
  background-color: #E8620F;
}

/*-- My Account Table -*/
.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}
.myaccount-table table th, .myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
}
.myaccount-table table td, .myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
}
.myaccount-table table td a:hover, .myaccount-table .table td a:hover {
  color: #ffffff;
}

.saved-message {
  border-top: 3px solid #E8620F;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

/*-- My Account Details Form -*/
.account-details-form h4 {
  margin: 0;
}
.account-details-form input {
  display: block;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 0;
  line-height: 24px;
  padding: 11px 25px;
  color: #656565;
}

/*---------------------------------------
    15. Newsletter CSS
-----------------------------------------*/
.newsletter-wrapper {
  text-align: center;
}
.newsletter-wrapper .small-text {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 23px;
}
@media only screen and (max-width: 479px) {
  .newsletter-wrapper .small-text {
    margin-bottom: 10px;
  }
}
.newsletter-wrapper .title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .newsletter-wrapper .title {
    font-size: 19px;
    margin-bottom: 10px;
  }
}
.newsletter-wrapper .short-desc {
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 23px;
}
@media only screen and (max-width: 479px) {
  .newsletter-wrapper .short-desc {
    margin-bottom: 30px;
  }
}

.newsletter-form {
  position: relative;
  border: 2px solid #ebebeb;
  width: 620px;
  max-width: 100%;
  margin: 0 auto;
}
.newsletter-form input {
  width: 100%;
  border: none;
  padding: 10px 20px;
  padding-right: 200px;
  line-height: 30px;
  height: 55px;
  color: #9d9d9d;
  font-size: 13px;
}
@media only screen and (max-width: 767px) {
  .newsletter-form input {
    height: 40px;
    padding-right: 150px;
  }
}
@media only screen and (max-width: 479px) {
  .newsletter-form input {
    padding-right: 128px;
    font-size: 11px;
  }
}
.newsletter-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: none;
  border: none;
  width: 200px;
  border-left: 2px solid #ebebeb;
  font-size: 13px;
  font-weight: 600;
  color: #292929;
}
@media only screen and (max-width: 767px) {
  .newsletter-form button {
    width: 150px;
  }
}
@media only screen and (max-width: 479px) {
  .newsletter-form button {
    width: 100px;
    font-size: 11px;
  }
}
.newsletter-form button:hover {
  color: #E8620F;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .newsletter-popup-area {
    display: none;
  }
}
.newsletter-popup-area:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.8;
  z-index: 99;
  visibility: visible;
}

.newsletter-popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 50px 20px;
  z-index: 999;
  width: 50%;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .newsletter-popup-content {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .newsletter-popup-content {
    width: 80%;
  }
}
.newsletter-popup-content .close-newsletter-popup {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px;
  background-color: #333;
  color: #ffffff;
}
.newsletter-popup-content .close-newsletter-popup:hover {
  background-color: #E8620F;
}
.newsletter-popup-content h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: #292929;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 15px;
}
.newsletter-popup-content .subscription-form form input {
  background: #EBEBEB none repeat scroll 0% 0%;
  border: medium none;
  height: 40px;
  width: 65%;
  margin: 20px auto;
  padding: 0 15px;
  font-size: 15px;
  display: block;
}
.newsletter-popup-content .subscription-form form button {
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  margin-bottom: 20px;
}
.newsletter-popup-content .subscription-form form button:hover {
  background-color: #E8620F;
  color: #ffffff;
}

/*---------------------------------------
    16. Testimonial CSS
-----------------------------------------*/
.testimonial-wrapper {
  padding: 60px 25px;
}

.single-testimonial-item {
  text-align: center;
}
.single-testimonial-item .testimonial-image {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 70px;
  overflow: hidden;
  vertical-align: middle;
}
.single-testimonial-item .testimonial-content {
  max-width: 900px;
  margin: 30px auto 30px auto;
}
.single-testimonial-item .testimonial-content .testimonial-text {
  color: #707070;
  font-size: 13px;
  font-style: 400;
  line-height: 2;
}
.single-testimonial-item .testimonial-content img {
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.single-testimonial-item .testimonial-content .testimonial-author {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #292929;
}

.testimonial-wrapper .slick-dots {
  width: 100%;
  padding: 0;
  margin: 0px 0 0;
  display: flex;
  justify-content: center;
}
.testimonial-wrapper .slick-dots li {
  margin: 0 5px;
}
.testimonial-wrapper .slick-dots li button {
  display: block;
  padding: 0;
  width: 14px;
  height: 14px;
  border: 2px solid #E8620F;
  background-color: #E8620F;
  text-indent: -9999px;
  border-radius: 100%;
}
.testimonial-wrapper .slick-dots li.slick-active button {
  border-color: #E8620F;
  background-color: #ffffff;
}

/*=====  End of testimonial  ======*/
/*---------------------------------------
    17. Modal CSS
-----------------------------------------*/
.quick-view-modal-container {
  display: block !important;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
.quick-view-modal-container.show {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}
.quick-view-modal-container .modal-dialog {
  max-width: 80%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 95%;
  }
}
@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .quick-view-modal-container .modal-dialog {
    max-width: 100%;
  }
}
.quick-view-modal-container .modal-header {
  border: none;
  padding-bottom: 0;
}
.quick-view-modal-container .modal-header button {
  z-index: 999;
}
.quick-view-modal-container .modal-body {
  padding: 30px 50px 70px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-view-modal-container .modal-body {
    padding: 30px 30px 30px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-body {
    padding: 30px 30px 30px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-body {
    padding: 15px 15px 15px 15px;
  }
}
@media only screen and (max-width: 479px) {
  .quick-view-modal-container .modal-body {
    padding: 15px 15px 15px 15px;
  }
}
.quick-view-modal-container .modal-content {
  border-radius: 0;
  max-height: 850px;
  overflow: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}
@media only screen and (max-width: 767px) {
  .quick-view-modal-container .modal-content {
    max-height: 500px;
    overflow: auto;
  }
}

/*---------------------------------------
    18. Sidebar CSS
-----------------------------------------*/
/*-- Sidebar --*/
.common-sidebar-widget {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 50px;
  padding-bottom: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .common-sidebar-widget {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .common-sidebar-widget {
    margin-bottom: 30px;
  }
}
.common-sidebar-widget.sidebar-two {
  padding: 0;
  background-color: transparent;
}
.common-sidebar-widget:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

/*-- Sidebar Title --*/
.sidebar-title {
  margin: 0;
  padding: 0 0 25px;
  display: block;
  font-size: 18px;
  color: #292929;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 24px;
}

/*-- Sidebar Search --*/
.sidebar-search form {
  display: flex;
  border: 1px solid #e5e6e7;
}
.sidebar-search form input {
  flex: 1 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0 15px;
  color: #929292;
}
.sidebar-search form button {
  max-width: 40px;
  flex: 1 0 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
}
.sidebar-search form button i {
  font-size: 20px;
  line-height: 40px;
}
.sidebar-search form button:hover {
  color: #E8620F;
}

/*-- Sidebar List --*/
.sidebar-list li {
  margin-bottom: 12px;
}
.sidebar-list li:last-child {
  margin-bottom: 0;
}
.sidebar-list li a {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  color: #292929;
  display: block;
}
.sidebar-list li a img {
  width: 25px;
  margin-right: 15px;
}
.sidebar-list li a i {
  margin-right: 10px;
  font-size: 10px;
}
.sidebar-list li a:hover {
  color: #E8620F;
}

/*-- Sidebar Price --*/
.sidebar-price {
  overflow: hidden;
}
.sidebar-price #price-range {
  position: relative;
  width: 100%;
  height: 5px;
  margin: 7px 0;
  border-radius: 50px;
  background-color: #ccc;
  border: 0;
}
.sidebar-price #price-range .ui-slider-range {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #E8620F;
}
.sidebar-price #price-range .ui-slider-handle {
  position: absolute;
  top: 50%;
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  border: 0;
}
.sidebar-price .price-amount {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 0;
  color: #292929;
  border: none;
  background-color: transparent;
  display: block;
}
.sidebar-price button {
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 13px;
}

/*-- Sidebar Compare --*/
.compare-products-list {
  overflow: hidden;
}
.compare-products-list ul {
  margin-bottom: 20px;
}
.compare-products-list ul li {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
}

.remove-compare {
  float: right;
  display: inline-block;
  padding-right: 10px;
}

.clear-btn {
  float: left;
  height: 35px;
  line-height: 35px;
  display: inline-block;
}

.compare-btn {
  float: right;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  text-transform: uppercase;
  font-size: 13px;
}

/*-- Sidebar Blog --*/
.sidebar-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}
.sidebar-blog:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.sidebar-blog .image {
  max-width: 50px;
  flex: 1 0 50px;
  height: 50px;
}
.sidebar-blog .image img {
  width: 100%;
}
.sidebar-blog .content {
  flex: 1 0 calc(100% - 50px);
  padding-left: 15px;
}
.sidebar-blog .content h5 {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
.sidebar-blog .content p {
  margin-bottom: 0;
}
.sidebar-blog .content span {
  font-size: 13px;
  display: block;
  line-height: 18px;
}

/*-- Sidebar Tags --*/
.sidebar-tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.sidebar-tag li {
  padding: 5px;
}
.sidebar-tag li a {
  display: block;
  border: 1px solid #e5e6e7;
  padding: 9px 20px;
  font-size: 13px;
  color: #292929;
}
.sidebar-tag li a:hover {
  background-color: #292929;
  border-color: #292929;
  color: #ffffff;
}

/*-- Sidebar Product --*/
.sidebar-product {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7;
}
.sidebar-product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.sidebar-product .image {
  max-width: 80px;
  flex: 1 0 80px;
}
.sidebar-product .image img {
  width: 100%;
  border: 1px solid #f1f2f3;
}
.sidebar-product .content {
  flex: 1 0 calc(100% - 80px);
  padding-left: 15px;
}
.sidebar-product .content .title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.sidebar-product .content .price span.old {
  margin-left: 10px;
  color: #929292;
  text-decoration: line-through;
}
.sidebar-product .content .ratting {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.sidebar-product .content .ratting i {
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  color: #E8620F;
}
.sidebar-product .content .ratting i:last-child {
  margin-right: 0;
}

/*---------------------------------------
    19. Contact CSS
-----------------------------------------*/
/*-- Map --*/
.contact-map {
  height: 600px;
  width: 100%;
}

/*-- Contact Information --*/
.contact-information h3 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .contact-information h3 {
    font-size: 24px;
  }
}
.contact-information ul {
  color: #292929;
}
.contact-information ul li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.contact-information ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.contact-information ul li .icon {
  display: inline-block;
}
.contact-information ul li .icon i {
  font-size: 25px;
}
.contact-information ul li .text {
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 15px;
  font-weight: 500;
}
.contact-information ul li .text span, .contact-information ul li .text a {
  display: block;
  line-height: 24px;
  max-width: 230px;
}

/*-- Contact Form --*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap.margin-0 {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-form-wrap.margin-0 {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrap {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-form-wrap {
    margin-top: 40px;
  }
}
.contact-form-wrap h3 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .contact-form-wrap h3 {
    font-size: 24px;
  }
}
.contact-form-wrap input {
  width: 100%;
  height: 50px;
  border: 1px solid #dddddd;
  padding: 5px 20px;
  color: #929292;
  border-radius: 5px;
  height: 45px;
}
.contact-form-wrap input:focus {
  border-color: #777777;
  color: #111111;
}
.contact-form-wrap textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  color: #929292;
  resize: none;
  border-radius: 5px;
}
.contact-form-wrap textarea:focus {
  border-color: #777777;
  color: #111111;
}
.contact-form-wrap button {
  margin-top: 20px;
}

.form-messege.success {
  color: #1dbc51;
  font-weight: 700;
}

.form-messege.error {
  color: #ff1313;
}

/*---------------------------------------
    20. 404 CSS
-----------------------------------------*/
.error-text > h1 {
  font-size: 200px;
  color: #E8620F;
  font-weight: 900;
  letter-spacing: 10px;
  line-height: 220px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-text > h1 {
    font-size: 180px;
    line-height: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > h1 {
    font-size: 160px;
    line-height: 160px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .error-text > h1 {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .error-text > h1 {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 15px;
  }
}
.error-text > h2 {
  font-size: 32px;
  line-height: 47px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #292929;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > h2 {
    font-size: 28px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .error-text > h2 {
    font-size: 28px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 575px) {
  .error-text > h2 {
    font-size: 20px;
    line-height: 24px;
  }
}
.error-text > p {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  margin: 20px auto 30px;
  color: #929292;
  max-width: 700px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-text > p {
    font-size: 18px;
    line-height: 24px;
    max-width: 550px;
  }
}
@media only screen and (max-width: 767px) {
  .error-text > p {
    font-size: 18px;
    line-height: 24px;
    max-width: 450px;
  }
}
@media only screen and (max-width: 575px) {
  .error-text > p {
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }
}

.search-error form {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 500px;
}
@media only screen and (max-width: 575px) {
  .search-error form {
    width: 100%;
  }
}
.search-error input {
  background: none;
  border: 1px solid #e9e9e9;
  color: #333333;
  float: left;
  font-size: 14px;
  height: 50px;
  padding: 0 40px 0 15px;
  width: 100%;
  line-height: 50px;
}
.search-error button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4f4f4f;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 50px;
}
.search-error button:hover {
  color: #E8620F;
}

.error-button a {
  background: #E8620F none repeat scroll 0 0;
  border-radius: 35px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 30px;
  padding: 0 30px;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-button a {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .error-button a {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .error-button a {
    margin-bottom: 15px;
  }
}
.error-button a:hover {
  background: #333;
}

/*----------------------------------------*/
/*  21. Footer CSS
/*----------------------------------------*/
/*-- Footer Widget --*/
.footer-widget .footer-logo {
  margin-bottom: 20px;
}
.footer-widget .footer-logo a {
  display: block;
}
.footer-widget .footer-logo a img {
  width: 170px;
}
.footer-widget .title {
  color: #f6f6f6;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .title {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-widget .title {
    margin-bottom: 15px;
  }
}
.footer-widget p {
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget p {
    font-size: 12px;
  }
}
.footer-widget .opeaning-title {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 0;
}
.footer-widget ul.address li {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}
.footer-widget ul.address li:last-child {
  margin-bottom: 0;
}
.footer-widget ul.address li i {
  margin-right: 15px;
  line-height: 28px;
  font-size: 18px;
}
.footer-widget ul.address li span {
  overflow: hidden;
}
.footer-widget ul.address li a {
  display: block;
}
.footer-widget ul.address li a:hover {
  color: #E8620F;
}
.footer-widget > .ft-menu {
  display: inline-block;
}
.footer-widget > .ft-menu > li {
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 13px;
}
.footer-widget > .ft-menu > li:last-child {
  margin-bottom: 0;
}
.footer-widget > .ft-menu > li > a {
  text-transform: capitalize;
  text-decoration: none;
  display: inherit;
  transition: all 0.4s ease-out;
}
.footer-widget > .ft-menu > li > a:hover {
  color: #E8620F;
}

/*-- Footer Social --*/
.f-social-title {
  margin-top: 30px;
}
.f-social-title h4 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
}

.footer-social {
  display: flex;
  float: right;
}
.footer-social a {
  margin-right: 25px;
  display: block;
}
.footer-social a:last-child {
  margin-right: 0;
}
.footer-social a:hover {
  color: #E8620F;
}
.footer-social a i {
  display: block;
  font-size: 14px;
  line-height: 28px;
}

/*-- Footer News Letter --*/
.footer-newsletter {
  display: flex;
  flex-wrap: wrap;
}
.footer-newsletter input {
  width: calc(100% - 40px);
  max-width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: #ffffff;
  line-height: 24px;
  padding: 7px 15px;
  font-size: 13px;
}
.footer-newsletter button {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #E8620F;
  color: #ffffff;
}

/*-- Footer Bottom --*/
/*-- Copyright --*/
@media only screen and (max-width: 767px) {
  .copyright {
    text-align: center !important;
  }
}
.copyright p {
  line-height: 30px;
  font-size: 13px;
}
@media only screen and (max-width: 575px) {
  .copyright p {
    font-size: 12px;
  }
}
.copyright p a {
  color: #E8620F;
}
.copyright p a:hover {
  color: #E8620F;
}

@media only screen and (max-width: 767px) {
  .footer-nav {
    text-align: center !important;
  }
}
.footer-nav ul li {
  display: inline-block;
  margin: 0 40px 0 0;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .footer-nav ul li {
    margin: 0 25px 0 0;
  }
}
.footer-nav ul li:last-child {
  margin-right: 0;
}
.footer-nav ul li a {
  font-size: 13px;
  line-height: 19px;
}

/*# sourceMappingURL=style.css.map */




/************************* added css*************************/

.img-fluid{
  margin-bottom: 25px;
  color: black;
}



.GlobalDesc{
  width: 100%;
  padding-right: 20px;
  margin-bottom: 150px;
}

.infoImage{
  width: 100%;
  height: 580px;
  background-color: white;
  filter: brightness(95%);
  text-align: center;
}


.infoImager{
  width: 100%;
  height: 500px;
  background-color: white;
  filter: brightness(95%);
  text-align: center;
}

.infoImg{
  position: relative;
  top: 22%;
}

.trInfo{
  border: none;
  border-top: 1px solid white;
  border-bottom: 1px solid rgb(92, 92, 92);
  padding-bottom: 0px;
  padding-top: 20px;
  height: 40px;
  font-size: 14px;
}

.tdInfo{
  border: none;
}

.tdInfo1{
  border: none;
  text-align: right;
}


.TableInfo{
  border: none;
  margin-top: 50px;
}


.titleInfo{
  margin-top: 75px;
  font-size: 18px;
  text-transform: uppercase;
  color: rgb(238,107,38);
  font-weight: bolder;
}

.paraCode{
  font-size: 14px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.formTitleConfig{
  width: 100%;
  color: rgb(238,107,38);
  text-align: left;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 60px;
  font-size: 1.4em;
  margin-top: 50px;
  text-decoration: underline;
}

.codeGenerate{
  margin-top: 40px;
  text-align: center;
}

.codeGenerateDesc{
  margin-top: 55px;
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: rgb(253, 253, 253);
 padding-left: 30px;
 padding-right: 30px;
}

.downloadFich{
  position: relative;
  display: inline;
  color: black;
  top: -8px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.downloadFich:hover{
 color: rgb(95, 95, 95);
}
.DDICFich{
  font-size: 25px;
  color: black;
}
.DDICFich:hover{
  color: rgb(95, 95, 95);
}
.indiceCode{
  font-size: 16px;
  text-transform: none;
  font-weight: bold;
  font-style: normal;
  margin-right: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.btnGenerate{
  height: 70px;
  width: 180px;
  background-color:rgb(172, 172, 172);;
  padding-top: 10px;
  border: 3px solid white;
  border-radius: 0%;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  margin-top: 40px;
}
.btnGenerate:hover{
  height: 70px;
  width: 180px;
  background-color: rgb(172, 172, 172);;
  padding-top: 10px;
  border: none;
  border-radius: 0%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}
.btnGenerate:focus{
  height: 70px;
  width: 180px;
  background-color: rgb(172, 172, 172);;
  padding-top: 10px;
  border: none;
  border-radius: 0%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.btnGenerate:visited{
  height: 70px;
  width: 180px;
  background-color: rgb(172, 172, 172);
  padding-top: 10px;
  border: none;
  border-radius: 0%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.btnGenerate:focus-visible{
  height: 70px;
  width: 180px;
  background-color: rgb(172, 172, 172);
  padding-top: 10px;
  border: none;
  border-radius: 0%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.inputGenerate{
  height: 60px;
  text-align: left;
  border: 1px solid black;
}

li.gobackcategory{
  color: rgb(172, 172, 172);
  cursor: pointer;
}
li.gobackcategory:hover{
  color: #E8620F;
}

.single-banner-item .banner-content .bannerr{
  font-size: 2em;
  font-weight: 500;
}

.slick-prev {
  background: black;
  visibility: hidden;
}

.slick-next {
  background: black;
  visibility: hidden;
}
.slick-dots li {
  visibility: hidden;
}
.slick-dots li button:before {
  visibility: visible;
  content: " ";
  background: #D8D8D8;
  opacity: 1;
  height: 4px;
  width: 40px;
  margin-top: 20px;
}
.slick-dots li.slick-active button:before {
  visibility: visible;
  content: " ";
  background: #E8620F;
  opacity: 1;
  height: 4px;
  width: 40px;
}

.pageNumber{
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image .title{
  font-family: Arial Black;
  font-weight: 200;
  font-size: 12px;
}

.consulter .slick-dots li {
  visibility: hidden;
}
.consulter .slick-dots li button:before, .getCaresoule .slick-dots li button:before {
  visibility: hidden;
  content: " ";
  background: #D8D8D8;
  opacity: 1;
  height: 4px;
  width: 40px;
  margin-top: 20px;
}
.consulter .slick-dots li.slick-active button:before, .getCaresoule .slick-dots li.slick-active button:before {
  visibility: hidden;
  content: " ";
  background: #E8620F;
  opacity: 1;
  height: 4px;
  width: 40px;
}

.lg-image{
  filter: brightness(92%);
  background-color: white;
  padding: 18%;
}

.getCaresoule{
  margin-top: 20px;
}

.getCaresoule .slick-prev {
  color: black;
  visibility: visible;
  position: absolute;
  left: 91.2%;
}

.getCaresoule .slick-next {
  color: black;
  visibility: visible;
}

.getCaresoule .slick-arrow {
  background: black;
  visibility: visible;
   background: white;
   position: absolute;
   top: 8%;
}

.getCaresoule .slick-prev:before, .getCaresoule .slick-next:before{
  color: black;
}

.getCaresoule .slick-prev:hover:before, .getCaresoule .slick-next:hover:before{
  color: white;
  background-color: #E8620F;
}


.getCaresoule  .slick-prev:before, .slick-next:before  {
  content: '' !important;
  
}

/*************************** navbar ***********************************/

.header.NavBar{
  position: sticky;
}
.mobile-menu {
  display: none;
}

.scrollTop {
  position: fixed; 
  width: 10%;
  bottom: 60px;
  right: 0px;
  align-items: right;
  height: 40px;
  color: #E8620F;
  justify-content: right;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
/**************************** search part ***************************/

.searchComponent{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0);
  z-index: 1000;
}

.searchComponent img{
  position: fixed;
  width: 35px;
  height: 35px;
  right: 3%;
  top: 3%;
  cursor: pointer;
}
.search{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: -1000;
}

.search .searchTerm{
  height: 55px;
  border-radius: 0%;

}
.search .searchButton{
  height: 55px;
  border-radius: 0%;

}
.under{
  width: 70px;
  height: 2px;
  border-radius: 50%;
  background-color: #E8620F;
}
.search{
    padding: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) }
@media all and (max-width: 1200px) {
  .product-image .title{
    line-height: 20px;
  }
  .main-menu > ul > li > a {
    font-size: 12px;
    margin: 0 0 0 0;
    font-weight: 400;
    line-height: 0;
  }
  .main-menu{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media all and (max-width: 1024px) {
  .searchBody{
    padding: 150px;
    width:100%;
    height: 100%;
    position: relative
  }
  .search{
    padding: 150px;
   }
  .Categories{
    height: 280px;
  }
  .pagination{
    margin-top: -25px;
    margin-bottom: -10px;
  }
  .AboutUs{
    display: flex;
    justify-content: center;
  }
  .getCaresoule .slick-arrow{
    width: 25px;
    height: 25px;
  }
  .getCaresoule .slick-prev {
    left: 90%;
  }
  .getCaresoule{
    margin-bottom: -90px;
  }
  .product-details-left{
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 20px;
    filter: brightness(85%);
  }
  .product-details-left .lg-image {
    filter: brightness(100%);
  }
  .product-details-left .lg-image img{
    display: table-cell;
    vertical-align: middle;
  }
  .bannerr{
    width: 50%;
    font-size: 13px;
  }
  .single-banner-item .banner-content.banner-content-two h6 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .single-banner-item .banner-image .myhome{
    height: 300px;
  }
  
  .calculate-shipping h4{
    font-size: 16px;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 991px) {
  .single-banner-item .banner-content.banner-content-two{
    max-width: 95%;
  }
  .mobile-menu {
    display: block;
    height: 66px;
    z-index: 999;
    background-color: black;
    width: 100%;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }
  .searchContentM{
    position: relative;
  }
  .Categories{
    height: 310px;
    width: 100%;
  }
  .banner-content h3{
    font-size: 14px;
  }
  .bannerC{
    width: 100%;
    font-size: 13px;
  }

  .CCategories{
    display: flex;
    justify-content: center;
  }
  .searchBody{
    width:100%;
    height: 100%;
    position: relative
  }
  .search{
    padding: 30px;
    position: absolute;
    top: 35%;
    left: 35%;
    transform: translate(-35%, -35%);
   }
  .mymobileLogo{
    width: 180px;
    height: 60px;
  }

 
  /**************************** next ***********************/

    /*Mobile View */
    .headerM {
      position: fixed;
      width: 100%;
      height: 200vh;
      z-index: 10;
      background-color: #fff;
      /* margin-top: 10px; */
      padding: 30px;
      padding-top: 100px;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .headerM li{
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 1.1em;
      text-transform: uppercase;
    }

    .languages{
      position: absolute;
      top: 2%;
      right: 2%;
    }
    .languages img{
      width: 25px;
      height: 25px;
      margin-right: 20px;
    }
    .languages ul{
      display: flex;
      flex-direction: row;
    }
    .searchhere{
      width: 100%;
      height: 50px;
    }
    .search{
      margin-top: 100px;
    }
    .fixedMobile{
      position: fixed;
      height: 84px;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .scrollTop {
      width: 10%;
      bottom: 60px;
      right: 11px;
    }


  /***********************************************************/
  .product-details-left .lg-image{
    position: absolute;
    top: 20%;
    padding: 0;
  }
  .single-banner-item .banner-content.banner-content-two{
    right: 0;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .single-banner-item .banner-content.banner-content-two h6.col-md-6 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .cart-summary .cart-summary-button, .FicheTechnique{
    width: 270px;
  }
 
}
@media all and (max-width: 480px) {
  .about-us-image img{
    height: 180px;
  }
  .searchButton{
    height: 55px;
  }
  .search{
    padding: 20px; }
  .Categories{
    height: 280px;
    width: 100%;
  }
  .about-us-area .container{
    padding: 0;
  }
  .cart-section .container{
    padding: 0;
  }
  .getCaresoule{
    margin-top: 60px;
    margin-bottom: -90px;
  }
  .getCaresoule .slick-arrow{
    width: 25px;
    height: 25px;
  }
  .getCaresoule .slick-prev {
    left: 76%;
  }
  .product-details-left{
    display: block;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 20px;
    filter: brightness(85%);
  }
  .product-details-left .lg-image {
    filter: brightness(100%);
  }
  .product-details-left .lg-image img{
    display: block;
  }
  .product-details-left .lg-image{
    position: relative;
    top: 0;
    padding: 0;
  }
  .cart-summary-wrap h3{
    padding-right: 0;
  }
  video{
    width: 100%;
  }
  .single-banner-item .banner-content.banner-content-two h6 {
    width: fit-content;
    margin-top: 20px;
  }
  .single-banner-item .banner-content.banner-content-two{
    flex: 0 0 90%;
    max-width: 90%;
  }
}

@media all and (max-width: 321px) {
  .postinfo-wrapper .post-info .blog-post-description{
    padding: 15px;
  }
  .about-us-area .container{
    padding: 15px;
  }
  .cart-section .container{
    padding: 15px;
  }
}


.d-flex{
  padding-right: 15px;
}

.myLang{
  margin-top: 7px;
}


.selectLang{
  /* border-radius: 50%; */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  width: 73px;
  padding-left: 5px;
  height: 25px;
  text-align: center;
  text-align-last: center;
  font-size: 1.1em;
  font-weight: 600;
  padding-top: 1px;
  background-color: #000;
  color: #fff;
  border: none;
}

.selectLang::-ms-expand {
  display: none;
}