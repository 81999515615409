
.FourOhFour {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
  
  .FourOhFour  .code {
    font-family: 'Alfa Slab One', cursive;
    font-size: 90px;
    height: 80vh;
    color: black;
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;
  }


.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 200px;
    left: -47px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    text-align: center;
    margin-top: 3px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }


  .nav-linksP {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 1rem;
    padding-top: 18px;
    margin-bottom: 10px;
  }

  .logoBlack{
    display: none;
  }
  .navbar.active .logoBlack{
    display: block;
  }
  .navbar.active .logoWhite{
    display: none;
  }

 .navbar.active .nav-linksP{
      color: white;
  }

  .navbar.active .nav-linksP:hover{
    color: rgb(255, 255, 255);
    border-bottom: 2px solid rgb(218, 218, 218);
}

  .nav-linksP:focus {
    color: rgb(218, 218, 218);
  }
  
  .nav-linksP:hover {
    border-bottom: 2px solid rgb(168, 168, 168);
    transition: all 0.2s ease-out;
    color: rgb(105, 103, 103);
  }

  .navbar.active .nav-linksP{
    color: black;
}

.navbar.active .nav-linksP:hover{
  color: rgb(68, 68, 68);
  border-bottom: 2px solid rgb(68, 68, 68);
}



.nav-linksPC {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
  height: 100%;
  font-size: 1.2rem;
  margin-bottom: 10px;
  width: fit-content;
  margin-left: 20px;
  margin-top: -2px;
}

.nav-linksSearch{
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
  height: 100%;
  font-size: 1.2rem;
  margin-bottom: 10px;
  width: fit-content;
  margin-left: 10px;
  margin-top: -1px;
}

.nav-linksPC:hover {
  color: rgb(218, 218, 218);;
}

.navbar.active .nav-linksPC{
  color: black;
}

.navbar{
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: black;
    text-transform: capitalize;
    }
    
.navbar.active{
    background-color: rgb(243, 243, 243);
    opacity: 0.8;
    height: 70px;
  }

.navbar-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    max-width: 100%;
  }
  
  .navbar-logo {
    position: fixed;
    left: 4%;
    cursor: pointer;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 90%;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-menu.active{
      color: black;
  } 

  .nav-item {
    height: 70px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

 .navbar.active .nav-links{
      color: black;
  }

  .navbar.active .nav-links:hover{
    color: rgb(68, 68, 68);
    border-bottom: 4px solid rgb(68, 68, 68);
}

  .nav-links:focus {
    color: rgb(218, 218, 218);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid rgb(168, 168, 168);
    transition: all 0.2s ease-out;
    color: rgb(168, 168, 168);
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }



  
  .nav-links
  {
      position: relative;
      transition: 0.2s ease color;
  }
  
  .nav-links:before, .nav-links:after
  {
      content: "";
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.2s ease transform;
  }
  
  .nav-links:before
  {
      top: 0;
      left: 10px;
      width: 6px;
      height: 6px;
  }
  
  .nav-links:after
  {
      top: 5px;
      left: 18px;
      width: 4px;
      height: 4px
  }
  .nav-links:nth-child(1):before
  {
      background-color: rgb(0, 255, 85);
  }
  
  .nav-links:nth-child(1):after
  {
      background-color: rgb(255, 145, 0);
  }
  
  .nav-links:hover:before, .nav-links:hover:after
  {
      transform: scale(1);
  }
  

  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      padding-top: 120px;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
        position: absolute;
        top: 0%;
        left: -3%;
      }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0%;
      right: 0%;
      margin-top: -24px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 90%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

    
    .dropdown-content {
      width: 100%;
      left: 0%;
      right: 0%;
      box-shadow: none;
      padding: 12px 16px;
      z-index: 1;
      text-align: center;
      align-items: center;
      margin-top: 0.1rem;
    }
    
    .nav-linksP:hover {
     border: none;
     text-align: center;
     width: 100%;
     color: rgb(63, 63, 63);
    }

  }